// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Navigation from './components/Navigation';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import JobManagement from './components/JobManagement';
import QuoteGenerator from './components/QuoteGenerator';
import ShoppingListGenerator from './components/ShoppingListGenerator';
import DocumentSender from './components/DocumentSender';
import ClientManagement from './components/ClientManagement';
import QuotationManagement from './components/QuotationManagement';
import EmployeeManagement from './components/EmployeeManagement';
import AssignmentArea from './components/AssignmentArea';
import LoginPage from './components/LoginPage';
import EmployeeLogin from './components/EmployeeLogin';
import EmployeeDashboard from './components/EmployeeDashboard';
import EmployeeAssignments from './components/EmployeeAssignments';
import EmployeeNavigation from './components/EmployeeNavigation';
import AssignmentOverview from './components/AssignmentOverview';
import GlobalHelmet from './components/GlobalHelmet';
import LandingPage from './components/LandingPage';

const PrivateRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }
  
  return currentUser.role !== 'employee' ? children : <Navigate to="/employee-login" replace />;
};

const EmployeeRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (!currentUser) {
    return <Navigate to="/employee-login" replace />;
  }
  
  return currentUser.role === 'employee' ? children : <Navigate to="/login" replace />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
      <GlobalHelmet />
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/employee-login" element={<EmployeeLogin />} />

          {/* Employee routes */}
          <Route path="/employee-area/*" element={
            <EmployeeRoute>
              <div>
                <EmployeeNavigation />
                <Routes>
                  <Route path="/" element={<EmployeeDashboard />} />
                  <Route path="/assignments" element={<EmployeeAssignments />} />
                </Routes>
              </div>
            </EmployeeRoute>
          } />

          {/* Admin routes */}
          <Route path="/*" element={
            <PrivateRoute>
              <div className="min-h-screen bg-gray-100">
                <Navigation />
                <div className="container mx-auto px-4 py-8">
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/jobs" element={<JobManagement />} />
                    <Route path="/quote" element={<QuoteGenerator />} />
                    <Route path="/shopping-list" element={<ShoppingListGenerator />} />
                    <Route path="/send-document" element={<DocumentSender />} />
                    <Route path="/clients" element={<ClientManagement />} />
                    <Route path="/quotations" element={<QuotationManagement />} />
                    <Route path="/employee-management" element={<EmployeeManagement />} />
                    <Route path="/assign-tasks/:employeeId" element={<AssignmentArea />} />
                    <Route path="/assignment-overview" element={<AssignmentOverview />} />
                  </Routes>
                </div>
              </div>
            </PrivateRoute>
          } />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;