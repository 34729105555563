import React from 'react';
import { Helmet } from 'react-helmet';

const GlobalHelmet = ({ title, description }) => {
  const defaultTitle = "Epeo - Gestione Personale, Clienti e Progetti";
  const defaultDescription = "Epeo: la soluzione completa per la gestione del personale, clienti e progetti del tuo business. Ottimizza la tua azienda con strumenti avanzati e intuitivi.";

  return (
    <Helmet>
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      {/* You can add other common meta tags here */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || defaultTitle} />
      <meta name="twitter:description" content={description || defaultDescription} />
    </Helmet>
  );
};

export default GlobalHelmet;
