// src/components/Dashboard.js

import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function Dashboard() {
  const { currentUser } = useAuth();

  // Function to check if the user is an admin
  const isAdmin = currentUser && currentUser.role === 'admin';

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
      <p className="mb-4">Benvenuto, {currentUser.email}</p>
      
      {/* Common sections for all users */}
      <h2 className="text-xl font-semibold mb-2">Sezioni Generali</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
        <DashboardCard title="Impostazioni" link="/settings" />
        <DashboardCard title="Gestione Clienti" link="/clients" />
        <DashboardCard title="Gestione Lavori" link="/jobs" />
        <DashboardCard title="Genera Preventivo" link="/quote" />
        <DashboardCard title="Gestione Preventivi" link="/quotations" />
        <DashboardCard title="Lista della Spesa" link="/shopping-list" />
      </div>

      {/* Admin-only sections */}
      {isAdmin && (
        <>
          <h2 className="text-xl font-semibold mb-2">Sezioni Amministrative</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
            <DashboardCard title="Gestione Dipendenti" link="/employee-management" />
            <DashboardCard title="Monitoraggio Dipendenti" link="/employee-monitoring" />
            <DashboardCard title="Rapporti e Analisi" link="/reports" />
            <DashboardCard title="Gestione Utenti" link="/user-management" />
            <DashboardCard title="Configurazione Sistema" link="/system-config" />
            <DashboardCard title="Log di Attività" link="/activity-logs" />
          </div>
        </>
      )}

      {/* Employee management section (visible to admins and users who can manage employees) */}
      {(isAdmin || currentUser.canManageEmployees) && (
        <>
          <h2 className="text-xl font-semibold mb-2">Gestione del Personale</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
            <DashboardCard title="Gestione Dipendenti" link="/employee-management" />
            <DashboardCard title="Assegnazione Compiti" link="/task-assignment" />
          </div>
        </>
      )}
    </div>
  );
}

function DashboardCard({ title, link }) {
  return (
    <Link 
      to={link} 
      className="block p-6 bg-white shadow rounded-lg hover:shadow-md transition-shadow"
    >
      <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
      <p className="mt-2 text-sm text-gray-600">Clicca per gestire</p>
    </Link>
  );
}

export default Dashboard;