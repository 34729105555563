// src/components/QuotationManagement.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { initializeFirebase } from '../firebase/config';
import { collection, query, where, getDocs, doc, deleteDoc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function QuotationManagement() {
  const { currentUser } = useAuth();
  const [quotes, setQuotes] = useState([]);
  const [clients, setClients] = useState({});
  const [jobs, setJobs] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [db, setDb] = useState(null);
  const [error, setError] = useState('');
  const [userSettings, setUserSettings] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const setup = async () => {
      const { db } = await initializeFirebase();
      setDb(db);
    };
    setup();
  }, []);

  useEffect(() => {
    if (db && currentUser) {
      fetchQuotes();
      fetchClients();
      fetchJobs();
      fetchUserSettings();
    }
  }, [db, currentUser]);

  const fetchQuotes = async () => {
    if (!db) return;
    try {
      const quotesQuery = query(collection(db, 'quotes'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(quotesQuery);
      const fetchedQuotes = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setQuotes(fetchedQuotes);
    } catch (error) {
      console.error('Errore nel recupero dei preventivi:', error);
      setError('Impossibile recuperare i preventivi. Per favore riprova.');
    }
  };

  const fetchClients = async () => {
    if (!db) return;
    try {
      const clientsQuery = query(collection(db, 'clients'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(clientsQuery);
      const clientsData = {};
      querySnapshot.forEach(doc => {
        clientsData[doc.id] = doc.data();
      });
      setClients(clientsData);
    } catch (error) {
      console.error('Errore nel recupero dei clienti:', error);
    }
  };

  const fetchJobs = async () => {
    if (!db) return;
    try {
      const jobsQuery = query(collection(db, 'jobs'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(jobsQuery);
      const jobsData = {};
      querySnapshot.forEach(doc => {
        jobsData[doc.id] = doc.data();
      });
      setJobs(jobsData);
    } catch (error) {
      console.error('Errore nel recupero dei lavori:', error);
    }
  };

  const fetchUserSettings = async () => {
    if (!db) return;
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        setUserSettings(userDoc.data());
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
      setError('Unable to fetch user settings. Please try again.');
    }
  };

  const handleDeleteQuote = async (quoteId) => {
    if (!db) return;
    try {
      await deleteDoc(doc(db, 'quotes', quoteId));
      setQuotes(quotes.filter(quote => quote.id !== quoteId));
    } catch (error) {
      console.error('Errore nell\'eliminazione del preventivo:', error);
      setError('Impossibile eliminare il preventivo. Per favore riprova.');
    }
  };

  const handleEditQuote = (quoteId) => {
    navigate(`/quote?edit=${quoteId}`);
  };

  const handleGenerateShoppingList = (quoteId) => {
    navigate(`/shopping-list?id=${quoteId}`);
  };

  const filteredQuotes = quotes.filter(quote => {
    const client = clients[quote.clientId];
    if (!client) return false;
    
    const clientName = `${client.firstName} ${client.lastName}`.toLowerCase();
    const companyName = (client.companyName || '').toLowerCase();
    const jobNames = quote.jobs.map(job => jobs[job.id]?.name.toLowerCase() || '').join(' ');
    
    return clientName.includes(searchTerm.toLowerCase()) ||
           companyName.includes(searchTerm.toLowerCase()) ||
           jobNames.includes(searchTerm.toLowerCase());
  });

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Data non disponibile';
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleDateString('it-IT', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  // Function to calculate and display the total based on Regime Forfettario setting
  const calculateDisplayTotal = (quote) => {
    if (userSettings.regimeForfettario) {
      // In Regime Forfettario, we don't apply IVA
      return `€${quote.subtotal.toFixed(2)}`;
    } else {
      // Normal regime, include IVA
      return `€${quote.total.toFixed(2)}`;
    }
  };

  return (
<div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Gestione Preventivi</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Cerca per cliente, azienda o lavoro..."
        className="w-full p-2 border rounded mb-4"
      />

      <div className="space-y-4">
        {filteredQuotes.map(quote => {
          const client = clients[quote.clientId];
          return (
            <div key={quote.id} className="border p-4 rounded shadow-md">
              <h2 className="text-xl font-semibold mb-2">
                Preventivo del {formatDate(quote.createdAt)}
              </h2>
              <p className="mb-1"><strong>Cliente:</strong> {client ? `${client.firstName} ${client.lastName}` : 'Cliente non trovato'}</p>
              {client && client.companyName && <p className="mb-1"><strong>Azienda:</strong> {client.companyName}</p>}
              <p className="mb-1"><strong>Lavori:</strong> {quote.jobs.map(job => jobs[job.id]?.name).join(', ')}</p>
              <p className="mb-2"><strong>Totale:</strong> {calculateDisplayTotal(quote)}</p>
              {!userSettings.regimeForfettario && (
                <p className="mb-2"><strong>IVA (22%):</strong> €{quote.tax.toFixed(2)}</p>
              )}
              <div className="flex flex-wrap gap-2">
                <button
                  onClick={() => handleEditQuote(quote.id)}
                  className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600"
                >
                  Modifica
                </button>
                <button
                  onClick={() => handleGenerateShoppingList(quote.id)}
                  className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                >
                  Genera Lista della Spesa
                </button>
                <button
                  onClick={() => handleDeleteQuote(quote.id)}
                  className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                >
                  Elimina
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default QuotationManagement;