// src/components/EmployeeManagement.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { initializeFirebase } from '../firebase/config';
import { collection, addDoc, query, where, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth, signOut, updateProfile } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { Link } from 'react-router-dom';
import { inviaCredenzialiDipendente } from '../utils/emailService';

function EmployeeManagement() {
  const { currentUser } = useAuth();
  const [db, setDb] = useState(null);
  const [auth, setAuth] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [newEmployee, setNewEmployee] = useState({ firstName: '', lastName: '', email: '' });
  const [editingEmployee, setEditingEmployee] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const setup = async () => {
      const { db, app } = await initializeFirebase();
      setDb(db);
      setAuth(getAuth(app));
      console.log('Firebase initialized');
    };
    setup();
  }, []);

  useEffect(() => {
    if (db && currentUser) {
      console.log('Fetching employees for user:', currentUser.uid);
      fetchEmployees();
    }
  }, [db, currentUser]);

  const fetchEmployees = async () => {
    if (!db) return;
    try {
      let employeesQuery;
      if (currentUser.role === 'admin') {
        employeesQuery = query(collection(db, 'employees'));
      } else {
        employeesQuery = query(collection(db, 'employees'), where('employerId', '==', currentUser.uid));
      }
      console.log('Executing query:', employeesQuery);
      const querySnapshot = await getDocs(employeesQuery);
      console.log('Query snapshot size:', querySnapshot.size);
      const fetchedEmployees = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log('Fetched employees:', fetchedEmployees);
      setEmployees(fetchedEmployees);
    } catch (error) {
      console.error('Error fetching employees:', error);
      setError('Unable to fetch employees. Please try again.');
    }
  };

  const generatePassword = () => {
    return Math.random().toString(36).slice(-8);
  };

  const handleAddEmployee = async (e) => {
    e.preventDefault();
    if (!db) return;
  
    setIsLoading(true);
    setError('');
    setSuccess('');
  
    try {
      const password = generatePassword();
  
      // Get the current app's config
      const currentApp = auth.app;
      const currentConfig = currentApp.options;
  
      // Create a secondary app instance
      const secondaryApp = initializeApp(currentConfig, 'Secondary');
      const secondaryAuth = getAuth(secondaryApp);
  
      try {
        // Create new employee account using the secondary app
        const userCredential = await createUserWithEmailAndPassword(secondaryAuth, newEmployee.email, password);
        
        // Set display name for the user
        await updateProfile(userCredential.user, {
          displayName: `${newEmployee.firstName} ${newEmployee.lastName}`,
        });

        // Add employee to Firestore
        const employeeData = {
          firstName: newEmployee.firstName,
          lastName: newEmployee.lastName,
          email: newEmployee.email,
          role: 'employee', // Automatically set role to 'employee'
          uid: userCredential.user.uid,
          employerId: currentUser.uid,
          createdAt: new Date()
        };
        await addDoc(collection(db, 'employees'), employeeData);

        // Invia email con le credenziali
        const test = await inviaCredenzialiDipendente(
          newEmployee.email,
          newEmployee.firstName,
          newEmployee.email, // Usando l'email come nome utente
          password
        );

        console.log(test)
  
        setNewEmployee({ firstName: '', lastName: '', email: '' });
        setSuccess(`Employee added successfully. Email: ${newEmployee.email}, Temporary Password: ${password}`);
        fetchEmployees();
      } finally {
        // Sign out from the secondary app
        await signOut(secondaryAuth);
      }
    } catch (error) {
      console.error('Error adding employee:', error);
      setError(`Unable to add employee: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateEmployee = async (e) => {
    e.preventDefault();
    if (!db || !editingEmployee) return;
    try {
      console.log('Updating employee:', editingEmployee.id);
      await updateDoc(doc(db, 'employees', editingEmployee.id), {
        firstName: editingEmployee.firstName,
        lastName: editingEmployee.lastName,
        email: editingEmployee.email
        // Note: We're not updating the role here as it's fixed as 'employee'
      });
      console.log('Employee updated successfully');
      setSuccess('Employee updated successfully');
      setEditingEmployee(null);
      fetchEmployees();
    } catch (error) {
      console.error('Error updating employee:', error);
      setError(`Unable to update employee: ${error.message}`);
    }
  };

  const handleDeleteEmployee = async (employeeId) => {
    if (!db) return;
    try {
      console.log('Deleting employee:', employeeId);
      await deleteDoc(doc(db, 'employees', employeeId));
      console.log('Employee deleted successfully');
      setSuccess('Employee deleted successfully');
      fetchEmployees();
    } catch (error) {
      console.error('Error deleting employee:', error);
      setError(`Unable to delete employee: ${error.message}`);
    }
  };

  const filteredEmployees = employees.filter(employee =>
    employee.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    employee.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    employee.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!currentUser.canManageEmployees && currentUser.role !== 'admin') {
    return <p>Non Hai i permessi per gestire i dipendenti.</p>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Gestione dipendenti</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}
      
      <form onSubmit={editingEmployee ? handleUpdateEmployee : handleAddEmployee} className="mb-8">
        <input
          type="text"
          value={editingEmployee ? editingEmployee.firstName : newEmployee.firstName}
          onChange={(e) => editingEmployee ? setEditingEmployee({...editingEmployee, firstName: e.target.value}) : setNewEmployee({...newEmployee, firstName: e.target.value})}
          placeholder="Nome"
          className="w-full p-2 border rounded mb-2"
          required
        />
        <input
          type="text"
          value={editingEmployee ? editingEmployee.lastName : newEmployee.lastName}
          onChange={(e) => editingEmployee ? setEditingEmployee({...editingEmployee, lastName: e.target.value}) : setNewEmployee({...newEmployee, lastName: e.target.value})}
          placeholder="Cognome"
          className="w-full p-2 border rounded mb-2"
          required
        />
        <input
          type="email"
          value={editingEmployee ? editingEmployee.email : newEmployee.email}
          onChange={(e) => editingEmployee ? setEditingEmployee({...editingEmployee, email: e.target.value}) : setNewEmployee({...newEmployee, email: e.target.value})}
          placeholder="Email"
          className="w-full p-2 border rounded mb-2"
          required
        />
        <button 
          type="submit" 
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
          disabled={isLoading}
        >
          {isLoading ? 'Processing...' : (editingEmployee ? 'Update Employee' : 'Add Employee')}
        </button>
        {editingEmployee && (
          <button
            type="button"
            onClick={() => setEditingEmployee(null)}
            className="ml-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            Cancel
          </button>
        )}
      </form>

      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Ricerca dipendenti..."
        className="w-full p-2 border rounded mb-4"
      />

      <div className="space-y-4">
        {filteredEmployees.length === 0 ? (
          <p>Nessun dipendente trovato.</p>
        ) : (
          filteredEmployees.map(employee => (
            <div key={employee.id} className="border p-4 rounded">
              <h2 className="text-xl font-semibold">{employee.firstName} {employee.lastName}</h2>
              <p className="text-gray-600">Ruolo: Dipendente</p>
              <p className="text-gray-600">Email: {employee.email}</p>
              <div className="mt-2 space-x-2">
                <button
                  onClick={() => setEditingEmployee(employee)}
                  className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteEmployee(employee.id)}
                  className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                >
                  Delete
                </button>
                <Link 
                  to={`/assign-tasks/${employee.id}`} 
                  className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600 inline-block"
                >
                  Assign Tasks
                </Link>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default EmployeeManagement;