// src/components/AssignmentArea.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { initializeFirebase } from '../firebase/config';
import { collection, query, where, getDocs, doc, getDoc, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';

function AssignmentArea() {
  // Extract employeeId from URL parameters
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  // State for Firebase database instance
  const [db, setDb] = useState(null);
  
  // State for employee data
  const [employee, setEmployee] = useState(null);
  
  // States for job and quote data
  const [jobs, setJobs] = useState([]);
  const [quotes, setQuotes] = useState([]);
  
  // State for task type (job or quote)
  const [taskType, setTaskType] = useState('job');
  
  // States for date and time of execution
  const [executionDate, setExecutionDate] = useState('');
  const [executionTime, setExecutionTime] = useState('');
  
  // State for additional notes
  const [notes, setNotes] = useState('');
  
  // State for existing assignments
  const [assignments, setAssignments] = useState([]);
  
  // States for error and success messages
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // States for client data and selection
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [clientSearchTerm, setClientSearchTerm] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);
  
  // States for filtering assignments
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterTaskType, setFilterTaskType] = useState('all');
  
  // States for quote search and filtering
  const [quoteSearchTerm, setQuoteSearchTerm] = useState('');
  const [filteredQuotes, setFilteredQuotes] = useState([]);

  // State for managing multiple jobs and their materials
  const [selectedJobs, setSelectedJobs] = useState([]);
  
  // State for selected quote (when assigning a quote)
  const [selectedQuote, setSelectedQuote] = useState('');

  // Effect hook to initialize Firebase
  useEffect(() => {
    const setup = async () => {
      const { db } = await initializeFirebase();
      setDb(db);
    };
    setup();
  }, []);

  // Effect hook to fetch initial data when component mounts
  useEffect(() => {
    if (db && currentUser && employeeId) {
      fetchEmployee();
      fetchJobs();
      fetchQuotesWithClientDetails();
      fetchClients();
    }
  }, [db, currentUser, employeeId]);

  // Effect hook to fetch assignments when employee data is available
  useEffect(() => {
    if (db && employee) {
      fetchAssignments();
    }
  }, [db, employee]);

  // Effect hook to filter clients based on search term
  useEffect(() => {
    const filtered = clients.filter(client =>
      `${client.firstName} ${client.lastName}`.toLowerCase().includes(clientSearchTerm.toLowerCase()) ||
      client.companyName.toLowerCase().includes(clientSearchTerm.toLowerCase())
    );
    setFilteredClients(filtered);
  }, [clients, clientSearchTerm]);

  // Effect hook to filter quotes based on search term
  useEffect(() => {
    const filtered = quotes.filter(quote =>
      quote.clientName.toLowerCase().includes(quoteSearchTerm.toLowerCase()) ||
      quote.title.toLowerCase().includes(quoteSearchTerm.toLowerCase())
    );
    setFilteredQuotes(filtered);
  }, [quotes, quoteSearchTerm]);

  // Function to fetch employee data
  const fetchEmployee = async () => {
    if (!db) return;
    try {
      const employeeDoc = await getDoc(doc(db, 'employees', employeeId));
      if (employeeDoc.exists()) {
        setEmployee(employeeDoc.data());
      } else {
        setError('Dipendente non trovato');
      }
    } catch (error) {
      console.error('Errore nel recupero dei dettagli del dipendente:', error);
      setError('Impossibile recuperare i dettagli del dipendente. Per favore riprova.');
    }
  };

  // Function to fetch all jobs
  const fetchJobs = async () => {
    if (!db) return;
    try {
      const jobsQuery = query(collection(db, 'jobs'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(jobsQuery);
      const fetchedJobs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobs(fetchedJobs);
    } catch (error) {
      console.error('Errore nel recupero dei lavori:', error);
      setError('Impossibile recuperare i lavori. Per favore riprova.');
    }
  };

  // Function to fetch quotes with client details
  const fetchQuotesWithClientDetails = async () => {
    if (!db) return;
    try {
      const quotesQuery = query(collection(db, 'quotes'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(quotesQuery);
      const quotesPromises = querySnapshot.docs.map(async (quoteDoc) => {
        const quoteData = quoteDoc.data();
        const clientDoc = await getDoc(doc(db, 'clients', quoteData.clientId));
        const clientData = clientDoc.exists() ? clientDoc.data() : null;
        return {
          id: quoteDoc.id,
          ...quoteData,
          clientName: clientData ? `${clientData.firstName} ${clientData.lastName}` : 'Unknown',
          clientCompany: clientData ? clientData.companyName : 'Unknown'
        };
      });
      const fetchedQuotes = await Promise.all(quotesPromises);
      setQuotes(fetchedQuotes);
      setFilteredQuotes(fetchedQuotes);
    } catch (error) {
      console.error('Errore nel recupero dei preventivi:', error);
      setError('Impossibile recuperare i preventivi. Per favore riprova.');
    }
  };

  // Function to fetch existing assignments
  const fetchAssignments = async () => {
    if (!db || !employee) return;
    try {
      const assignmentsQuery = query(collection(db, 'assignments'), where('employeeId', '==', employee.uid));
      const querySnapshot = await getDocs(assignmentsQuery);
      const fetchedAssignments = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAssignments(fetchedAssignments);
    } catch (error) {
      console.error('Errore nel recupero delle assegnazioni:', error);
      setError('Impossibile recuperare le assegnazioni. Per favore riprova.');
    }
  };

  // Function to fetch all clients
  const fetchClients = async () => {
    if (!db) return;
    try {
      const clientsQuery = query(collection(db, 'clients'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(clientsQuery);
      const fetchedClients = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setClients(fetchedClients);
    } catch (error) {
      console.error('Errore nel recupero dei clienti:', error);
      setError('Impossibile recuperare i clienti. Per favore riprova.');
    }
  };

  // Function to handle job selection
  const handleJobSelect = (jobId) => {
    const selectedJob = jobs.find(job => job.id === jobId);
    if (selectedJob) {
      const jobInstanceId = Date.now(); // Generate a unique instance ID
      setSelectedJobs([...selectedJobs, { 
        ...selectedJob, 
        instanceId: jobInstanceId,
        materials: (selectedJob.defaultMaterials || []).map(material => ({
          ...material,
          jobInstanceId: jobInstanceId
        }))
      }]);
    }
  };

  // Function to remove a selected job
  const handleRemoveJob = (instanceId) => {
    setSelectedJobs(selectedJobs.filter(job => job.instanceId !== instanceId));
  };

  // Function to handle changes in material details
  const handleMaterialChange = (jobInstanceId, materialIndex, field, value) => {
    setSelectedJobs(prevJobs => prevJobs.map(job => {
      if (job.instanceId === jobInstanceId) {
        const updatedMaterials = [...job.materials];
        updatedMaterials[materialIndex] = {
          ...updatedMaterials[materialIndex],
          [field]: value
        };
        return { ...job, materials: updatedMaterials };
      }
      return job;
    }));
  };

  // Function to add a new material to a job
  const handleAddMaterial = (jobInstanceId) => {
    setSelectedJobs(prevJobs => prevJobs.map(job => {
      if (job.instanceId === jobInstanceId) {
        return {
          ...job,
          materials: [...job.materials, { name: '', quantity: '', unit: '', jobInstanceId: jobInstanceId }]
        };
      }
      return job;
    }));
  };

  // Function to remove a material from a job
  const handleRemoveMaterial = (jobInstanceId, materialIndex) => {
    setSelectedJobs(prevJobs => prevJobs.map(job => {
      if (job.instanceId === jobInstanceId) {
        const updatedMaterials = [...job.materials];
        updatedMaterials.splice(materialIndex, 1);
        return { ...job, materials: updatedMaterials };
      }
      return job;
    }));
  };

  // Function to handle assignment submission
  const handleAssign = async (e) => {
    e.preventDefault();
    if (!db || !executionDate || !executionTime || !employee) return;
  
    try {
      console.log("Starting assignment process...");
      console.log("Task type:", taskType);
  
      let assignmentData = {
        employeeId: employee.uid,
        employerId: currentUser.uid,
        executionDate: new Date(`${executionDate}T${executionTime}`),
        status: 'assigned',
        notes: notes,
        createdAt: new Date()
      };
  
      if (taskType === 'job') {
        // Handle job assignment
        if (!selectedClient || selectedJobs.length === 0) {
          setError('Seleziona un cliente e almeno un lavoro.');
          return;
        }
        const clientRef = doc(db, 'clients', selectedClient);
        const clientDoc = await getDoc(clientRef);
        const clientData = clientDoc.data();
  
        assignmentData = {
          ...assignmentData,
          taskType: 'job',
          jobs: selectedJobs.map(job => ({
            id: job.id,
            name: job.name,
            instanceId: job.instanceId,
            materials: job.materials || []
          })),
          customerName: `${clientData.firstName} ${clientData.lastName}`,
          customerAddress: `${clientData.street}, ${clientData.city}, ${clientData.province} ${clientData.postalCode}`,
          customerPhone: clientData.phone
        };
      } else {
        // Handle quote assignment
        console.log("Handling quote assignment");
        console.log("Selected quote ID:", selectedQuote);
  
        if (!selectedQuote) {
          setError('Seleziona un preventivo.');
          return;
        }
  
        const quoteRef = doc(db, 'quotes', selectedQuote);
        const quoteDoc = await getDoc(quoteRef);
        const quoteData = quoteDoc.data();
  
        console.log("Raw quote data:", quoteData);
  
        if (!quoteData) {
          console.error("Quote data is undefined");
          setError('Impossibile recuperare i dati del preventivo.');
          return;
        }
  
        console.log("Quote jobs:", quoteData.jobs);
        console.log("Quote materials:", quoteData.materials);
  
        const clientRef = doc(db, 'clients', quoteData.clientId);
        const clientDoc = await getDoc(clientRef);
        const clientData = clientDoc.data();
  
        console.log("Client data:", clientData);
  
        if (!clientData) {
          console.error("Client data is undefined");
          setError('Impossibile recuperare i dati del cliente.');
          return;
        }
  
        // Transform quote jobs and associate materials
        const transformedJobs = (quoteData.jobs || []).map(job => {
          console.log("Processing job:", job);
          const jobMaterials = (quoteData.materials || []).filter(material => material.jobInstanceId === job.instanceId);
          console.log("Job materials:", jobMaterials);
  
          return {
            ...job,
            instanceId: job.instanceId || `${Date.now()}-${Math.random()}`,
            materials: jobMaterials.map(material => ({
              ...material,
              jobInstanceId: job.instanceId || job.id
            }))
          };
        });
  
        console.log("Transformed jobs:", transformedJobs);
  
        assignmentData = {
          ...assignmentData,
          taskType: 'quote',
          quoteId: selectedQuote,
          jobs: transformedJobs,
          customerName: `${clientData.firstName} ${clientData.lastName}`,
          customerAddress: `${clientData.street}, ${clientData.city}, ${clientData.province} ${clientData.postalCode}`,
          customerPhone: clientData.phone
        };
  
        console.log("Final assignment data:", assignmentData);
      }
  
      // Add the new assignment to Firestore
      const docRef = await addDoc(collection(db, 'assignments'), assignmentData);
      console.log("Assignment added with ID:", docRef.id);
  
      // Reset form and show success message
      setSuccess('Attività assegnata con successo');
      setSelectedJobs([]);
      setSelectedQuote('');
      setExecutionDate('');
      setExecutionTime('');
      setNotes('');
      setSelectedClient('');
      fetchAssignments();
    } catch (error) {
      console.error('Errore nell\'assegnazione dell\'attività:', error);
      setError('Impossibile assegnare l\'attività. Per favore riprova.');
    }
  };

  // Function to handle assignment deletion
  const handleDeleteAssignment = async (assignmentId) => {
    if (!db) return;
    if (window.confirm('Sei sicuro di voler eliminare questa assegnazione?')) {
      try {
        await deleteDoc(doc(db, 'assignments', assignmentId));
        setSuccess('Assegnazione eliminata con successo');
        fetchAssignments();
      } catch (error) {
        console.error('Errore nell\'eliminazione dell\'assegnazione:', error);
        setError('Impossibile eliminare l\'assegnazione. Per favore riprova.');
      }
    }
  };

  // Filter assignments based on status and task type
  const filteredAssignments = assignments.filter(assignment => {
    if (filterStatus !== 'all' && assignment.status !== filterStatus) return false;
    if (filterTaskType !== 'all' && assignment.taskType !== filterTaskType) return false;
    return true;
  });

  // Show loading state if employee data is not yet loaded
  if (!employee) {
    return <div>Caricamento...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Assegna Attività a {employee.firstName} {employee.lastName}</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}
  
      <form onSubmit={handleAssign} className="space-y-4">
        {/* Task Type Selection */}
        <div>
          <label className="block mb-2">Tipo di Attività</label>
          <select
            value={taskType}
            onChange={(e) => setTaskType(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="job">Lavoro</option>
            <option value="quote">Preventivo</option>
          </select>
        </div>
  
        {/* Job Assignment Section */}
        {taskType === 'job' && (
          <>
            {/* Client Selection for Job */}
            <div>
              <label className="block mb-2">Seleziona Cliente</label>
              <input
                type="text"
                value={clientSearchTerm}
                onChange={(e) => setClientSearchTerm(e.target.value)}
                placeholder="Cerca clienti..."
                className="w-full p-2 border rounded mb-2"
              />
              <select
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">Seleziona un cliente</option>
                {filteredClients.map(client => (
                  <option key={client.id} value={client.id}>
                    {client.firstName} {client.lastName} - {client.companyName || 'N/A'}
                  </option>
                ))}
              </select>
            </div>
            {/* Job Selection and Management */}
            <div>
              <label className="block mb-2">Aggiungi Lavori</label>
              <select
                onChange={(e) => handleJobSelect(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">Seleziona un lavoro</option>
                {jobs.map(job => (
                  <option key={job.id} value={job.id}>{job.name}</option>
                ))}
              </select>
            </div>
            {/* Display and Manage Selected Jobs */}
            {selectedJobs.map((job) => (
              <div key={job.instanceId} className="border p-4 rounded mb-4">
                <h3 className="font-bold">{job.name}</h3>
                <button
                  type="button"
                  onClick={() => handleRemoveJob(job.instanceId)}
                  className="mt-2 bg-red-500 text-white px-2 py-1 rounded text-sm"
                >
                  Rimuovi Lavoro
                </button>
                <h4 className="font-semibold mt-2">Materiali:</h4>
                {/* Display and Manage Materials for each Job */}
                {job.materials.map((material, materialIndex) => (
                  <div key={`${job.instanceId}-${materialIndex}`} className="flex space-x-2 mt-2">
                    <input
                      type="text"
                      value={material.name}
                      onChange={(e) => handleMaterialChange(job.instanceId, materialIndex, 'name', e.target.value)}
                      placeholder="Nome materiale"
                      className="flex-grow p-1 border rounded"
                    />
                    <input
                      type="number"
                      value={material.quantity}
                      onChange={(e) => handleMaterialChange(job.instanceId, materialIndex, 'quantity', e.target.value)}
                      placeholder="Quantità"
                      className="w-20 p-1 border rounded"
                    />
                    <input
                      type="text"
                      value={material.unit}
                      onChange={(e) => handleMaterialChange(job.instanceId, materialIndex, 'unit', e.target.value)}
                      placeholder="Unità"
                      className="w-20 p-1 border rounded"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveMaterial(job.instanceId, materialIndex)}
                      className="bg-red-500 text-white px-2 py-1 rounded text-sm"
                    >
                      Rimuovi
                    </button>
                  </div>
                ))}
                {/* Button to Add New Material */}
                <button
                  type="button"
                  onClick={() => handleAddMaterial(job.instanceId)}
                  className="mt-2 bg-green-500 text-white px-2 py-1 rounded text-sm"
                >
                  Aggiungi Materiale
                </button>
              </div>
            ))}
          </>
        )}
  
        {/* Quote Assignment Section */}
        {taskType === 'quote' && (
          <div>
            <label className="block mb-2">Seleziona Preventivo</label>
            <input
              type="text"
              value={quoteSearchTerm}
              onChange={(e) => setQuoteSearchTerm(e.target.value)}
              placeholder="Cerca preventivi..."
              className="w-full p-2 border rounded mb-2"
            />
            <select
              value={selectedQuote}
              onChange={(e) => setSelectedQuote(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="">Seleziona un preventivo</option>
              {filteredQuotes.map(quote => (
                <option key={quote.id} value={quote.id}>
                  {quote.title || `Preventivo per ${quote.clientName}`} - {quote.clientCompany}
                </option>
              ))}
            </select>
          </div>
        )}
  
        {/* Execution Date and Time Inputs */}
        <div>
          <label className="block mb-2">Data di Esecuzione</label>
          <input
            type="date"
            value={executionDate}
            onChange={(e) => setExecutionDate(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-2">Ora di Esecuzione</label>
          <input
            type="time"
            value={executionTime}
            onChange={(e) => setExecutionTime(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
  
        {/* Notes Input */}
        <div>
          <label className="block mb-2">Note</label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="w-full p-2 border rounded"
            rows="3"
          ></textarea>
        </div>
  
        {/* Submit Button */}
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Assegna Attività
        </button>
      </form>
  
      {/* Current Assignments Section */}
      <h2 className="text-xl font-bold mt-8 mb-4">Assegnazioni Attuali</h2>
      
      {/* Filters for Assignments */}
      <div className="mb-4 flex space-x-4">
        <div className="flex-1">
          <label className="block mb-2">Filtra per Stato</label>
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="all">Tutti</option>
            <option value="assigned">Assegnato</option>
            <option value="in progress">In Corso</option>
            <option value="completed">Completato</option>
          </select>
        </div>
        <div className="flex-1">
          <label className="block mb-2">Filtra per Tipo</label>
          <select
            value={filterTaskType}
            onChange={(e) => setFilterTaskType(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="all">Tutti</option>
            <option value="job">Lavoro</option>
            <option value="quote">Preventivo</option>
          </select>
        </div>
      </div>
  
      {/* Display Filtered Assignments */}
      {filteredAssignments.length === 0 ? (
        <p>Nessuna assegnazione trovata per questo dipendente.</p>
      ) : (
        <ul className="space-y-4">
          {filteredAssignments.map(assignment => (
            <li key={assignment.id} className="border p-4 rounded">
              <p><strong>Tipo di Attività:</strong> {assignment.taskType === 'job' ? 'Lavoro' : 'Preventivo'}</p>
              <p><strong>Cliente:</strong> {assignment.customerName}</p>
              <p><strong>Indirizzo:</strong> {assignment.customerAddress}</p>
              <p><strong>Telefono:</strong> {assignment.customerPhone}</p>
              <p><strong>Data e ora appuntamento:</strong> {new Date(assignment.executionDate.toDate()).toLocaleString()}</p>
              <p><strong>Stato:</strong> {
                assignment.status === 'assigned' ? 'Assegnato' :
                assignment.status === 'in progress' ? 'In Corso' :
                assignment.status === 'completed' ? 'Completato' : assignment.status
              }</p>
              <p><strong>Note:</strong> {assignment.notes || 'N/A'}</p>
              
              {/* Display Jobs and Materials for the Assignment */}
              {assignment.jobs && assignment.jobs.length > 0 && (
                <div>
                  <h4 className="font-semibold mt-2">Lavori e Materiali:</h4>
                  {assignment.jobs.map((job) => (
                    <div key={job.instanceId || job.id} className="ml-4">
                      <p><strong>{job.name}</strong></p>
                      <ul className="list-disc list-inside">
                        {job.materials.map((material, mIndex) => (
                          <li key={`${job.instanceId || job.id}-${mIndex}`}>
                            {material.name} - {material.quantity} {material.unit}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}
              
              {/* Delete Assignment Button */}
              <button
                onClick={() => handleDeleteAssignment(assignment.id)}
                className="mt-2 bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
              >
                Elimina Assegnazione
              </button>
            </li>
          ))}
        </ul>
      )}
  
      {/* Navigation Button */}
      <button
        onClick={() => navigate('/employee-management')}
        className="mt-8 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
      >
        Torna alla Gestione Dipendenti
      </button>
    </div>
  );
}

export default AssignmentArea;