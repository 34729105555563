import emailjs from '@emailjs/browser';
import EmailTemplates from './emailTemplates';

// Initialize EmailJS with your user ID
emailjs.init({
    publicKey: 's82-oXXT2BlEE9WuD',
    // Do not allow headless browsers
    blockHeadless: true
  });

/**
 * Sends an email using EmailJS
 * @param {string} to - Recipient's email address
 * @param {string} templateName - Name of the email template to use
 * @param {Object} templateData - Data to populate the template
 * @returns {Promise} - Resolves with the EmailJS response
 */
async function sendEmail(to, templateName, templateData) {
    if (!EmailTemplates[templateName]) {
      throw new Error(`Template "${templateName}" non trovato`);
    }
  
    const messageHtml = EmailTemplates[templateName](templateData);
  
    const emailParams = {
      to_email: to,
      to_name: templateData.firstName,
      from_name: templateData.companyName || "Nome della Tua Azienda",
      subject: templateData.subject,
      message_html: messageHtml,
    };
  
    try {
      const response = await emailjs.send(
        "service_4k6pi5f",
        "template_c6te0yo",
        emailParams
      );
      console.log('Email inviata con successo:', response);
      return response;
    } catch (error) {
      console.error('Errore nell\'invio dell\'email:', error);
      throw error;
    }
  }

/**
 * Sends an email with employee credentials
 * @param {string} to - Employee's email address
 * @param {string} firstName - Employee's first name
 * @param {string} username - Employee's username
 * @param {string} password - Employee's temporary password
 * @returns {Promise} - Resolves with the EmailJS response
 */
export function inviaCredenzialiDipendente(to, firstName, username, password) {
  return sendEmail(to, 'credenziali', {
    firstName,
    username,
    password,
    url: "https://manager.giallointeractive.com/employee-login",
    subject: `Benvenuto in Azienda, ${firstName}!`,
  });
}

/**
 * Sends a welcome email to a new employee
 * @param {string} to - Employee's email address
 * @param {string} firstName - Employee's first name
 * @param {string} companyName - Company name
 * @param {string} welcomeUrl - URL for the welcome page
 * @returns {Promise} - Resolves with the EmailJS response
 */
export function inviaEmailBenvenuto(to, firstName, companyName, welcomeUrl) {
  return sendEmail(to, 'benvenuto', {
    firstName,
    companyName,
    welcomeUrl,
    subject: `Benvenuto in ${companyName}, ${firstName}!`
  });
}

/**
 * Sends a reminder email for an event
 * @param {string} to - Recipient's email address
 * @param {string} firstName - Recipient's first name
 * @param {string} eventName - Name of the event
 * @param {string} eventDate - Date of the event
 * @param {string} eventUrl - URL for event details
 * @returns {Promise} - Resolves with the EmailJS response
 */
export function inviaPromemoria(to, firstName, eventName, eventDate, eventUrl) {
  return sendEmail(to, 'promemoria', {
    firstName,
    eventName,
    eventDate,
    eventUrl,
    subject: `Promemoria: ${eventName}`,
    companyName: "Nome della Tua Azienda"
  });
}

/**
 * Sends an email with information request from the landing page contact form
 * @param {string} to - Recipient's email address (usually the company's contact email)
 * @param {string} name - Name of the person requesting information
 * @param {string} email - Email of the person requesting information
 * @param {string} message - Message from the contact form
 * @returns {Promise} - Resolves with the EmailJS response
 */
export function inviaRichiestaInformazioni(to, name, email, message) {
  return sendEmail(to, 'richiestaInformazioni', {
    name,
    email,
    message,
    subject: `Nuova richiesta di informazioni da ${name}`,
    companyName: "Epeo"
  });
}