// src/components/JobManagement.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { initializeFirebase } from '../firebase/config';
import { collection, addDoc, query, where, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';

function JobManagement() {
  const { currentUser } = useAuth();
  const [jobs, setJobs] = useState([]);
  const [editingJob, setEditingJob] = useState(null);
  const [newJobName, setNewJobName] = useState('');
  const [newJobDescription, setNewJobDescription] = useState('');
  const [defaultMaterials, setDefaultMaterials] = useState([{ name: '', quantity: '', unit: '', price: '' }]);
  const [error, setError] = useState('');
  const [db, setDb] = useState(null);

  useEffect(() => {
    const setup = async () => {
      const { db } = await initializeFirebase();
      setDb(db);
    };
    setup();
  }, []);

  useEffect(() => {
    if (db && currentUser) {
      fetchJobs();
    }
  }, [db, currentUser]);

  const fetchJobs = async () => {
    if (!db) return;
    try {
      const jobsQuery = query(collection(db, 'jobs'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(jobsQuery);
      const fetchedJobs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobs(fetchedJobs);
    } catch (error) {
      console.error('Errore nel recupero dei lavori:', error);
      setError('Impossibile recuperare i lavori. Per favore riprova.');
    }
  };

  const handleAddJob = async (e) => {
    e.preventDefault();
    if (!db) return;
    try {
      const filteredMaterials = defaultMaterials.filter(material => material.name && material.quantity && material.unit);
      
      await addDoc(collection(db, 'jobs'), {
        userId: currentUser.uid,
        name: newJobName,
        description: newJobDescription,
        defaultMaterials: filteredMaterials,
        createdAt: new Date()
      });
      setNewJobName('');
      setNewJobDescription('');
      setDefaultMaterials([{ name: '', quantity: '', unit: '', price: '' }]);
      fetchJobs();
    } catch (error) {
      console.error('Errore nell\'aggiunta del nuovo lavoro:', error);
      setError('Impossibile aggiungere il nuovo lavoro. Per favore riprova.');
    }
  };

  const handleEditJob = async (e) => {
    e.preventDefault();
    if (!db || !editingJob) return;
    try {
      const filteredMaterials = editingJob.defaultMaterials.filter(material => material.name && material.quantity && material.unit);
      
      await updateDoc(doc(db, 'jobs', editingJob.id), {
        name: editingJob.name,
        description: editingJob.description,
        defaultMaterials: filteredMaterials,
      });
      setEditingJob(null);
      fetchJobs();
    } catch (error) {
      console.error('Errore nell\'aggiornamento del lavoro:', error);
      setError('Impossibile aggiornare il lavoro. Per favore riprova.');
    }
  };

  const handleDeleteJob = async (jobId) => {
    if (!db) return;
    try {
      await deleteDoc(doc(db, 'jobs', jobId));
      fetchJobs();
    } catch (error) {
      console.error('Errore nell\'eliminazione del lavoro:', error);
      setError('Impossibile eliminare il lavoro. Per favore riprova.');
    }
  };

  const handleAddMaterial = () => {
    if (editingJob) {
      setEditingJob({
        ...editingJob,
        defaultMaterials: [...editingJob.defaultMaterials, { name: '', quantity: '', unit: '', price: '' }]
      });
    } else {
      setDefaultMaterials([...defaultMaterials, { name: '', quantity: '', unit: '', price: '' }]);
    }
  };

  const handleMaterialChange = (index, field, value) => {
    if (editingJob) {
      const updatedMaterials = [...editingJob.defaultMaterials];
      updatedMaterials[index][field] = value;
      setEditingJob({ ...editingJob, defaultMaterials: updatedMaterials });
    } else {
      const updatedMaterials = [...defaultMaterials];
      updatedMaterials[index][field] = value;
      setDefaultMaterials(updatedMaterials);
    }
  };

  const handleRemoveMaterial = (index) => {
    if (editingJob) {
      const updatedMaterials = editingJob.defaultMaterials.filter((_, i) => i !== index);
      setEditingJob({ ...editingJob, defaultMaterials: updatedMaterials });
    } else {
      const updatedMaterials = defaultMaterials.filter((_, i) => i !== index);
      setDefaultMaterials(updatedMaterials);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Gestione Lavori</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={editingJob ? handleEditJob : handleAddJob} className="mb-8">
        <input
          type="text"
          value={editingJob ? editingJob.name : newJobName}
          onChange={(e) => editingJob ? setEditingJob({...editingJob, name: e.target.value}) : setNewJobName(e.target.value)}
          placeholder="Nome del Lavoro"
          className="w-full p-2 border rounded mb-2"
          required
        />
        <textarea
          value={editingJob ? editingJob.description : newJobDescription}
          onChange={(e) => editingJob ? setEditingJob({...editingJob, description: e.target.value}) : setNewJobDescription(e.target.value)}
          placeholder="Descrizione del Lavoro"
          className="w-full p-2 border rounded mb-2"
          rows="3"
          required
        ></textarea>
        
        <h3 className="font-semibold mt-4 mb-2">Materiali Predefiniti</h3>
        {(editingJob ? editingJob.defaultMaterials : defaultMaterials).map((material, index) => (
          <div key={index} className="flex flex-wrap mb-2">
            <input
              type="text"
              value={material.name}
              onChange={(e) => handleMaterialChange(index, 'name', e.target.value)}
              placeholder="Nome Materiale"
              className="flex-grow p-2 border rounded mr-2 mb-2 sm:mb-0"
            />
            <input
              type="number"
              value={material.quantity}
              onChange={(e) => handleMaterialChange(index, 'quantity', e.target.value)}
              placeholder="Quantità"
              className="w-full sm:w-24 p-2 border rounded mr-2 mb-2 sm:mb-0"
            />
            <input
              type="text"
              value={material.unit}
              onChange={(e) => handleMaterialChange(index, 'unit', e.target.value)}
              placeholder="Unità"
              className="w-full sm:w-24 p-2 border rounded mr-2 mb-2 sm:mb-0"
            />
            <input
              type="number"
              value={material.price}
              onChange={(e) => handleMaterialChange(index, 'price', e.target.value)}
              placeholder="Prezzo"
              className="w-full sm:w-24 p-2 border rounded mr-2 mb-2 sm:mb-0"
            />
            <button
              type="button"
              onClick={() => handleRemoveMaterial(index)}
              className="w-full sm:w-auto bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
            >
              Rimuovi
            </button>
          </div>
        ))}
        
        <div className="flex flex-wrap sm:flex-nowrap space-y-2 sm:space-y-0 sm:space-x-2 mt-4">
          <button
            type="button"
            onClick={handleAddMaterial}
            className="w-full sm:w-auto bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            Aggiungi Materiale
          </button>
          
          <button 
            type="submit" 
            className="w-full sm:w-auto bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            {editingJob ? 'Aggiorna Lavoro' : 'Aggiungi Nuovo Lavoro'}
          </button>
          
          {editingJob && (
            <button
              type="button"
              onClick={() => setEditingJob(null)}
              className="w-full sm:w-auto bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              Annulla Modifica
            </button>
          )}
        </div>
      </form>
      <div className="space-y-4">
        {jobs.map(job => (
          <div key={job.id} className="border p-4 rounded">
            <h2 className="text-xl font-semibold">{job.name}</h2>
            <p className="text-gray-600">{job.description}</p>
            {job.defaultMaterials && job.defaultMaterials.length > 0 && (
              <div className="mt-2">
                <h3 className="font-semibold">Materiali Predefiniti:</h3>
                <ul className="list-disc list-inside">
                  {job.defaultMaterials.map((material, index) => (
                    <li key={index}>{material.name} - Quantità: {material.quantity} {material.unit}, Prezzo: €{material.price}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="mt-2 flex space-x-2">
              <button
                onClick={() => setEditingJob(job)}
                className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600"
              >
                Modifica
              </button>
              <button
                onClick={() => handleDeleteJob(job.id)}
                className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
              >
                Elimina
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default JobManagement;