// src/components/Navigation.js

import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function Navigation() {
  // Hooks for authentication, navigation, and location
  const { currentUser, signOut } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  // State for managing mobile menu and user dropdown
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

  // State for managing screen size
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Menu items array
  const menuItems = [
    { path: '/dashboard', label: 'Dashboard' },
    { path: '/clients', label: 'Gestione Clienti' },
    { path: '/jobs', label: 'Inserimento Lavori' },
    { path: '/quotations', label: 'Preventivi' },
    { path: '/quote', label: 'Genera Preventivo' },
    { path: '/shopping-list', label: 'Lista Acquisti' },
    { path: '/employee-management', label: 'Dipendenti' },
    { path: '/assignment-overview', label: 'Stato attività' },
    { path: '/settings', label: 'Impostazioni' },
  ];

  // Effect hook to handle screen resize
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024); // Set breakpoint for small screens
    };

    handleResize(); // Call once to set initial state
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to handle user logout
  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Errore durante il logout', error);
    }
  };

  // Function to toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Function to toggle user dropdown
  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };

  return (
    <nav className="bg-blue-600 p-4">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          {/* Logo/Home link */}
          <Link to="/" className="text-white text-2xl font-bold">
            GestoreLavori
          </Link>

          {/* Hamburger menu for small screens */}
          {isSmallScreen && (
            <button onClick={toggleMobileMenu} className="text-white focus:outline-none">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          )}

          {/* Navigation menu for larger screens */}
          {!isSmallScreen && (
            <div className="hidden lg:flex space-x-4">
              {menuItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`text-white hover:text-blue-200 ${
                    location.pathname === item.path ? 'font-bold' : ''
                  }`}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          )}

          {/* User menu (visible on larger screens) */}
          {!isSmallScreen && currentUser && (
            <div className="relative">
              <button
                onClick={toggleUserDropdown}
                className="text-white hover:text-blue-200 focus:outline-none"
              >
                {currentUser.email}
              </button>
              {isUserDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Mobile menu (visible when toggled on small screens) */}
        {isSmallScreen && isMobileMenuOpen && (
          <div className="mt-4">
            {menuItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`block text-white hover:text-blue-200 py-2 ${
                  location.pathname === item.path ? 'font-bold' : ''
                }`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                {item.label}
              </Link>
            ))}
            {currentUser && (
              <button
                onClick={handleLogout}
                className="block w-full text-left text-white hover:text-blue-200 py-2"
              >
                Logout
              </button>
            )}
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navigation;