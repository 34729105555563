// src/components/DocumentSender.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { initializeFirebase } from '../firebase/config';
import { collection, query, where, getDocs } from 'firebase/firestore';

function DocumentSender() {
  const { currentUser } = useAuth();
  const [db, setDb] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [documentType, setDocumentType] = useState('quote');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const setup = async () => {
      const { db } = await initializeFirebase();
      setDb(db);
    };
    setup();
  }, []);

  useEffect(() => {
    if (db && currentUser) {
      fetchClients();
    }
  }, [db, currentUser]);

  const fetchClients = async () => {
    if (!db) return;
    try {
      const clientsQuery = query(collection(db, 'clients'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(clientsQuery);
      const fetchedClients = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setClients(fetchedClients);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError('Unable to fetch clients. Please try again.');
    }
  };

  const handleSendDocument = (e) => {
    e.preventDefault();
    // Here you would typically integrate with an email service or WhatsApp API
    console.log('Sending document:', { documentType, selectedClient, message });
    setSuccess(`${documentType.charAt(0).toUpperCase() + documentType.slice(1)} sent successfully!`);
    setError('');
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Send Document</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}
      <form onSubmit={handleSendDocument} className="space-y-4">
        <div>
          <label className="block mb-1">Document Type</label>
          <select
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="quote">Quote</option>
            <option value="shoppingList">Shopping List</option>
          </select>
        </div>
        <div>
          <label className="block mb-1">Select Client</label>
          <select
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select a client</option>
            {clients.map(client => (
              <option key={client.id} value={client.id}>
                {client.firstName} {client.lastName} - {client.companyName}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block mb-1">Message</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full p-2 border rounded"
            rows="3"
          ></textarea>
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Send Document
        </button>
      </form>
    </div>
  );
}

export default DocumentSender;