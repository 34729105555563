// src/components/Settings.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { initializeFirebase, getDb } from '../firebase/config';
import { doc, getDoc, setDoc } from 'firebase/firestore';

function Settings() {
  const { currentUser } = useAuth();
  const [settings, setSettings] = useState({
    hourlyRate: '',
    dailyRate: '',
    companyName: '',
    vatNumber: '',
    fiscalCode: '',
    address: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
    phone: '',
    email: '',
    logoDataUrl: '',
    regimeForfettario: false // New field for Regime Forfettario
  });
  const [db, setDb] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [logoFile, setLogoFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const setup = async () => {
      const { db } = await initializeFirebase();
      setDb(db);
    };
    setup();
  }, []);

  useEffect(() => {
    if (db && currentUser) {
      fetchSettings();
    }
  }, [db, currentUser]);

  const fetchSettings = async () => {
    if (!db) return;
    setIsLoading(true);
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        setSettings(userDoc.data());
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
      setError('Unable to fetch settings. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleLogoChange = (e) => {
    if (e.target.files[0]) {
      setLogoFile(e.target.files[0]);
    }
  };

  const convertLogoToDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!db) return;
    setIsLoading(true);
    setError('');
    setSuccess('');
    try {
      let updatedSettings = { ...settings };
      if (logoFile) {
        const logoDataUrl = await convertLogoToDataURL(logoFile);
        updatedSettings.logoDataUrl = logoDataUrl;
      }
      const userDocRef = doc(db, 'users', currentUser.uid);
      await setDoc(userDocRef, updatedSettings, { merge: true });
      setSettings(updatedSettings);
      setSuccess('Settings saved successfully!');
      setLogoFile(null);
    } catch (error) {
      console.error('Error saving settings:', error);
      setError('Failed to save settings. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Settings</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block mb-1">La tua tariffa oraria</label>
            <input
              type="number"
              name="hourlyRate"
              value={settings.hourlyRate}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">La tua tariffa giornaliera</label>
            <input
              type="number"
              name="dailyRate"
              value={settings.dailyRate}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">Nome società</label>
            <input
              type="text"
              name="companyName"
              value={settings.companyName}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">P. IVA</label>
            <input
              type="text"
              name="vatNumber"
              value={settings.vatNumber}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">Codice fiscale</label>
            <input
              type="text"
              name="fiscalCode"
              value={settings.fiscalCode}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">Indirizzo</label>
            <input
              type="text"
              name="address"
              value={settings.address}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">Città</label>
            <input
              type="text"
              name="city"
              value={settings.city}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">Provincia</label>
            <input
              type="text"
              name="province"
              value={settings.province}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">CAP</label>
            <input
              type="text"
              name="postalCode"
              value={settings.postalCode}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">Paese</label>
            <input
              type="text"
              name="country"
              value={settings.country}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">Telefono</label>
            <input
              type="tel"
              name="phone"
              value={settings.phone}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">Email</label>
            <input
              type="email"
              name="email"
              value={settings.email}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="col-span-2">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="regimeForfettario"
                checked={settings.regimeForfettario}
                onChange={handleChange}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="ml-2">Regime Forfettario</span>
            </label>
          </div>
        </div>
        <div>
          <label className="block mb-1">Il tuo logo</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleLogoChange}
            className="w-full p-2 border rounded"
          />
          {settings.logoDataUrl && (
            <img src={settings.logoDataUrl} alt="Company Logo" className="mt-2 max-w-xs" />
          )}
        </div>
        <button 
          type="submit" 
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
          disabled={isLoading}
        >
          {isLoading ? 'Saving...' : 'Save Settings'}
        </button>
      </form>
    </div>
  );
}

export default Settings;