// src/components/AssignmentOverview.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { initializeFirebase } from '../firebase/config';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';

function AssignmentOverview() {
  const { currentUser } = useAuth();
  const [assignments, setAssignments] = useState([]);
  const [filteredAssignments, setFilteredAssignments] = useState([]);
  const [employees, setEmployees] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isIndexError, setIsIndexError] = useState(false);

  // State for filters
  const [statusFilter, setStatusFilter] = useState('all');
  const [taskTypeFilter, setTaskTypeFilter] = useState('all');
  const [employeeFilter, setEmployeeFilter] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState({ start: '', end: '' });

  useEffect(() => {
    if (currentUser) {
      fetchAssignments();
      fetchEmployees();
    }
  }, [currentUser]);

  useEffect(() => {
    applyFilters();
  }, [assignments, statusFilter, taskTypeFilter, employeeFilter, dateRangeFilter]);

  const fetchAssignments = async () => {
    try {
      const { db } = await initializeFirebase();
      const assignmentsQuery = query(
        collection(db, 'assignments'),
        where('employerId', '==', currentUser.uid),
        orderBy('executionDate', 'desc')
      );
      const querySnapshot = await getDocs(assignmentsQuery);
      const fetchedAssignments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAssignments(fetchedAssignments);
      setIsIndexError(false);
    } catch (error) {
      console.error('Error fetching assignments:', error);
      if (error.code === 'failed-precondition') {
        setIsIndexError(true);
        setError('The query requires an index that is still being created. Please try again in a few minutes.');
      } else {
        setError('Failed to load assignments. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployees = async () => {
    try {
      const { db } = await initializeFirebase();
      const employeesQuery = query(collection(db, 'employees'), where('employerId', '==', currentUser.uid));
      const querySnapshot = await getDocs(employeesQuery);
      const employeesData = {};
      querySnapshot.forEach(doc => {
        const employeeData = doc.data();
        employeesData[employeeData.uid] = `${employeeData.firstName} ${employeeData.lastName}`;
      });
      setEmployees(employeesData);
    } catch (error) {
      console.error('Error fetching employees:', error);
      setError('Failed to load employee data. Some information may be missing.');
    }
  };

  const applyFilters = () => {
    let filtered = assignments;

    if (statusFilter !== 'all') {
      filtered = filtered.filter(assignment => assignment.status === statusFilter);
    }

    if (taskTypeFilter !== 'all') {
      filtered = filtered.filter(assignment => assignment.taskType === taskTypeFilter);
    }

    if (employeeFilter) {
      filtered = filtered.filter(assignment => 
        employees[assignment.employeeId]?.toLowerCase().includes(employeeFilter.toLowerCase())
      );
    }

    if (dateRangeFilter.start && dateRangeFilter.end) {
      const startDate = new Date(dateRangeFilter.start);
      const endDate = new Date(dateRangeFilter.end);
      filtered = filtered.filter(assignment => {
        const executionDate = assignment.executionDate.toDate();
        return executionDate >= startDate && executionDate <= endDate;
      });
    }

    setFilteredAssignments(filtered);
  };

  const formatDate = (date) => {
    if (!date) return 'N/A';
    const d = date.toDate();
    return d.toLocaleDateString('it-IT');
  };

  const formatTime = (date) => {
    if (!date) return 'N/A';
    const d = date.toDate();
    return d.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' });
  };

  const calculateElapsedTime = (startTime, endTime) => {
    if (!startTime || !endTime) return 'N/A';
    const start = startTime.toDate();
    const end = endTime.toDate();
    const elapsedMs = end - start;
    const hours = Math.floor(elapsedMs / (1000 * 60 * 60));
    const minutes = Math.floor((elapsedMs % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  if (loading) return <div className="p-4">Caricamento...</div>;
  if (error) return (
    <div className="p-4 text-red-500">
      <p>Errore: {error}</p>
      {isIndexError && (
        <button 
          onClick={fetchAssignments} 
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Riprova
        </button>
      )}
    </div>
  );

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Panoramica Assegnazioni</h1>

      {/* Filters */}
      <div className="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div>
          <label className="block mb-2">Stato</label>
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="all">Tutti</option>
            <option value="assigned">Assegnato</option>
            <option value="in progress">In Corso</option>
            <option value="completed">Completato</option>
          </select>
        </div>
        <div>
          <label className="block mb-2">Tipo di Attività</label>
          <select
            value={taskTypeFilter}
            onChange={(e) => setTaskTypeFilter(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="all">Tutti</option>
            <option value="job">Lavoro</option>
            <option value="quote">Preventivo</option>
          </select>
        </div>
        <div>
          <label className="block mb-2">Dipendente</label>
          <input
            type="text"
            value={employeeFilter}
            onChange={(e) => setEmployeeFilter(e.target.value)}
            placeholder="Cerca per nome dipendente"
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label className="block mb-2">Intervallo Date</label>
          <div className="flex space-x-2">
            <input
              type="date"
              value={dateRangeFilter.start}
              onChange={(e) => setDateRangeFilter({...dateRangeFilter, start: e.target.value})}
              className="w-1/2 p-2 border rounded"
            />
            <input
              type="date"
              value={dateRangeFilter.end}
              onChange={(e) => setDateRangeFilter({...dateRangeFilter, end: e.target.value})}
              className="w-1/2 p-2 border rounded"
            />
          </div>
        </div>
      </div>

      {/* Assignments Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2">Data Esecuzione</th>
              <th className="px-4 py-2">Tipo</th>
              <th className="px-4 py-2">Cliente</th>
              <th className="px-4 py-2">Dipendente</th>
              <th className="px-4 py-2">Stato</th>
              <th className="px-4 py-2">Data Inizio</th>
              <th className="px-4 py-2">Ora Inizio</th>
              <th className="px-4 py-2">Data Fine</th>
              <th className="px-4 py-2">Ora Fine</th>
              <th className="px-4 py-2">Tempo Trascorso</th>
            </tr>
          </thead>
          <tbody>
            {filteredAssignments.map(assignment => (
              <tr key={assignment.id} className="border-b">
                <td className="px-4 py-2">{formatDate(assignment.executionDate)}</td>
                <td className="px-4 py-2">{assignment.taskType === 'job' ? 'Lavoro' : 'Preventivo'}</td>
                <td className="px-4 py-2">{assignment.customerName}</td>
                <td className="px-4 py-2">{employees[assignment.employeeId] || 'N/A'}</td>
                <td className="px-4 py-2">
                  <span className={`px-2 py-1 rounded text-sm ${
                    assignment.status === 'assigned' ? 'bg-yellow-200' :
                    assignment.status === 'in progress' ? 'bg-blue-200' :
                    'bg-green-200'
                  }`}>
                    {assignment.status === 'assigned' ? 'Assegnato' :
                     assignment.status === 'in progress' ? 'In Corso' : 'Completato'}
                  </span>
                </td>
                <td className="px-4 py-2">{formatDate(assignment.startTime)}</td>
                <td className="px-4 py-2">{formatTime(assignment.startTime)}</td>
                <td className="px-4 py-2">{formatDate(assignment.endTime)}</td>
                <td className="px-4 py-2">{formatTime(assignment.endTime)}</td>
                <td className="px-4 py-2">{calculateElapsedTime(assignment.startTime, assignment.endTime)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {filteredAssignments.length === 0 && (
        <p className="text-center mt-4">Nessuna assegnazione trovata con i filtri applicati.</p>
      )}
    </div>
  );
}

export default AssignmentOverview;