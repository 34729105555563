// src/firebase/config.js

import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, connectAuthEmulator, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

let app;
let db;
let auth;
let storage;
let googleProvider;

const initializeFirebase = async () => {
  if (!app) {
    try {
      app = initializeApp(firebaseConfig);
      console.log('Firebase app initialized successfully');

      db = getFirestore(app);
      console.log('Firestore initialized');

      auth = getAuth(app);
      console.log('Firebase Auth initialized');

      // Set persistence to LOCAL
      await setPersistence(auth, browserLocalPersistence);
      console.log('Auth persistence set to LOCAL');

      storage = getStorage(app);
      console.log('Firebase Storage initialized');

      googleProvider = new GoogleAuthProvider();
      console.log('Google Auth Provider initialized');

      if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_FIREBASE_EMULATORS === 'true') {
        connectFirestoreEmulator(db, 'localhost', 8080);
        console.log('Connected to Firestore emulator');

        connectAuthEmulator(auth, 'http://localhost:9099');
        console.log('Connected to Auth emulator');

        connectStorageEmulator(storage, 'localhost', 9199);
        console.log('Connected to Storage emulator');
      }
    } catch (error) {
      console.error('Error initializing Firebase:', error);
      throw error;
    }
  }

  return { app, db, auth, storage, googleProvider };
};

const getDbInstance = () => {
  if (!db) {
    throw new Error('Firestore has not been initialized. Please call initializeFirebase first.');
  }
  return db;
};

const getAuthInstance = () => {
  if (!auth) {
    throw new Error('Firebase Auth has not been initialized. Please call initializeFirebase first.');
  }
  return auth;
};

const getStorageInstance = () => {
  if (!storage) {
    throw new Error('Firebase Storage has not been initialized. Please call initializeFirebase first.');
  }
  return storage;
};

export { initializeFirebase, getDbInstance, getAuthInstance, getStorageInstance };