// src/components/QuoteGenerator.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { initializeFirebase, getDb } from '../firebase/config';
import { collection, query, where, getDocs, doc, getDoc, addDoc, updateDoc } from 'firebase/firestore';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { useLocation, useNavigate } from 'react-router-dom';

function QuoteGenerator() {
  const { currentUser } = useAuth();
  const [db, setDb] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [materials, setMaterials] = useState([]);
  const [newMaterial, setNewMaterial] = useState({ name: '', quantity: '', unit: '', price: '' });
  const [userSettings, setUserSettings] = useState({});
  const [error, setError] = useState('');
  const [showPrices, setShowPrices] = useState(true);
  const [editingQuoteId, setEditingQuoteId] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const setup = async () => {
      const { db } = await initializeFirebase();
      setDb(db);
    };
    setup();
  }, []);

  useEffect(() => {
    if (db && currentUser) {
      fetchJobs();
      fetchClients();
      fetchUserSettings();

      const searchParams = new URLSearchParams(location.search);
      const editId = searchParams.get('edit');
      if (editId) {
        setEditingQuoteId(editId);
        fetchQuoteData(editId);
      }
    }
  }, [db, currentUser, location]);

  useEffect(() => {
    const filtered = clients.filter(client =>
      `${client.firstName} ${client.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.phone.includes(searchTerm) ||
      client.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredClients(filtered);
  }, [clients, searchTerm]);

  const fetchJobs = async () => {
    if (!db) return;
    try {
      const jobsQuery = query(collection(db, 'jobs'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(jobsQuery);
      const fetchedJobs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobs(fetchedJobs);
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setError('Unable to fetch jobs. Please try again.');
    }
  };

  const fetchClients = async () => {
    if (!db) return;
    try {
      const clientsQuery = query(collection(db, 'clients'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(clientsQuery);
      const fetchedClients = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setClients(fetchedClients);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError('Unable to fetch clients. Please try again.');
    }
  };

  const fetchUserSettings = async () => {
    if (!db) return;
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        setUserSettings(userDoc.data());
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
      setError('Unable to fetch user settings. Please try again.');
    }
  };

  const fetchQuoteData = async (quoteId) => {
    if (!db) return;
    try {
      const quoteDocRef = doc(db, 'quotes', quoteId);
      const quoteDoc = await getDoc(quoteDocRef);
      if (quoteDoc.exists()) {
        const quoteData = quoteDoc.data();
        setSelectedClient(quoteData.clientId);
        
        // Fetch full job details for each job in the quote
        const jobPromises = quoteData.jobs.map(async (jobInfo) => {
          const jobDoc = await getDoc(doc(db, 'jobs', jobInfo.id));
          if (jobDoc.exists()) {
            const jobData = jobDoc.data();
            return {
              ...jobData,
              id: jobInfo.id,
              instanceId: jobInfo.instanceId,
              hours: jobInfo.hours,
              days: jobInfo.days,
              timeUnit: jobInfo.timeUnit
            };
          }
          return null;
        });
        const loadedJobs = await Promise.all(jobPromises);
        setSelectedJobs(loadedJobs.filter(job => job !== null));

        setMaterials(quoteData.materials);
        setShowPrices(quoteData.showPrices);
      }
    } catch (error) {
      console.error('Error fetching quote:', error);
      setError('Unable to fetch quote data. Please try again.');
    }
  };

  const handleJobSelect = (jobId) => {
    const selectedJobData = jobs.find(job => job.id === jobId);
    if (selectedJobData) {
      addJobInstance(selectedJobData);
    }
  };

  const addJobInstance = (jobData) => {
    const newJob = { 
      ...jobData, 
      instanceId: Date.now(),
      hours: 0, 
      days: 0, 
      timeUnit: 'hours' 
    };
    setSelectedJobs([...selectedJobs, newJob]);
    const newMaterials = [
      ...materials,
      ...(jobData.defaultMaterials || []).map(material => ({
        ...material,
        price: material.price || '0',
        jobInstanceId: newJob.instanceId
      }))
    ];
    setMaterials(newMaterials);
  };

  const handleDuplicateJob = (job) => {
    addJobInstance(job);
  };

  const handleRemoveJob = (instanceId) => {
    setSelectedJobs(selectedJobs.filter(job => job.instanceId !== instanceId));
    setMaterials(materials.filter(material => material.jobInstanceId !== instanceId));
  };

  const handleJobTimeChange = (instanceId, field, value) => {
    const updatedJobs = selectedJobs.map(job => 
      job.instanceId === instanceId ? { ...job, [field]: value } : job
    );
    setSelectedJobs(updatedJobs);
  };

  const handleJobTimeUnitChange = (instanceId, unit) => {
    const updatedJobs = selectedJobs.map(job => 
      job.instanceId === instanceId ? { ...job, timeUnit: unit } : job
    );
    setSelectedJobs(updatedJobs);
  };

  const handleAddMaterial = () => {
    setMaterials([...materials, { ...newMaterial, jobInstanceId: 'custom' }]);
    setNewMaterial({ name: '', quantity: '', unit: '', price: '' });
  };

  const handleUpdateMaterial = (index, field, value) => {
    const updatedMaterials = [...materials];
    updatedMaterials[index][field] = value;
    setMaterials(updatedMaterials);
  };

  const handleRemoveMaterial = (index) => {
    const updatedMaterials = materials.filter((_, i) => i !== index);
    setMaterials(updatedMaterials);
  };

  const calculateSubtotal = () => {
    const materialsCost = materials.reduce((total, mat) => total + (parseFloat(mat.quantity) * parseFloat(mat.price)), 0);
    const laborCost = selectedJobs.reduce((total, job) => {
      const rate = job.timeUnit === 'hours' ? userSettings.hourlyRate : userSettings.dailyRate;
      return total + (parseFloat(job.timeUnit === 'hours' ? job.hours : job.days) * rate);
    }, 0);
    return materialsCost + laborCost;
  };

  const calculateTax = () => {
    return userSettings.regimeForfettario ? 0 : calculateSubtotal() * 0.22; // 22% VAT if not in Regime Forfettario
  };

  const calculateTotal = () => {
    return calculateSubtotal() + calculateTax();
  };

  const generatePDF = async () => {
    const doc = new jsPDF();
    const tableColumn = showPrices ? ["Descrizione", "Prezzo", "Quantità", "Totale"] : ["Descrizione", "Quantità"];
    let tableRows = [];

    const selectedClientData = clients.find(client => client.id === selectedClient);

    // Add logo if available
    if (userSettings.logoDataUrl) {
      try {
        const img = new Image();
        img.src = userSettings.logoDataUrl;
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
        const aspectRatio = img.width / img.height;
        const maxWidth = 30;
        const width = Math.min(img.width, maxWidth);
        const height = width / aspectRatio;
        doc.addImage(userSettings.logoDataUrl, 'PNG', 14, 10, width, height);
      } catch (error) {
        console.error('Error adding logo to PDF:', error);
      }
    }

    // Add user data to top left corner
    doc.setFontSize(10);
    doc.setTextColor(100);
    let userDataY = 15;
    const userDataX = 135;

    // Add company name in bold
    doc.setFont(undefined, 'bold');
    doc.text(`${userSettings.companyName || 'Company Name Not Set'}`, userDataX, userDataY);
    doc.setFont(undefined, 'normal');

    doc.text(`P.IVA: ${userSettings.vatNumber || 'Not Set'}`, userDataX, userDataY += 5);
    doc.text(`Codice Fiscale: ${userSettings.fiscalCode || 'Not Set'}`, userDataX, userDataY += 5);
    doc.text(`${userSettings.address || ''}, ${userSettings.city || ''}`, userDataX, userDataY += 5);
    doc.text(`${userSettings.province || ''} ${userSettings.postalCode || ''}`, userDataX, userDataY += 5);
    doc.text(`Tel: ${userSettings.phone || ''}`, userDataX, userDataY += 5);
    doc.text(`Email: ${userSettings.email || ''}`, userDataX, userDataY += 5);

    // Reset text color and font size
    doc.setTextColor(0);
    doc.setFontSize(16);
    doc.text("Preventivo", 14, 50);
    
    doc.setFontSize(12);
    let y = 60;
    doc.text(`Numero Preventivo: ${editingQuoteId || Date.now()}`, 14, y);
    doc.text(`Data: ${new Date().toLocaleDateString()}`, 14, y += 7);
    
    if (selectedClientData) {
      doc.text(`Cliente: ${selectedClientData.firstName} ${selectedClientData.lastName}`, 14, y += 10);
      doc.text(`Azienda: ${selectedClientData.companyName || 'N/A'}`, 14, y += 7);
      doc.text(`Indirizzo: ${selectedClientData.street}, ${selectedClientData.city}, ${selectedClientData.province} ${selectedClientData.postalCode}`, 14, y += 7);
      doc.text(`Tel: ${selectedClientData.phone}`, 14, y += 7);
      doc.text(`Email: ${selectedClientData.email}`, 14, y += 7);
    }

    if (showPrices) {
      materials.forEach(material => {
        tableRows.push([
          material.name,
          `€${material.price}`,
          `${material.quantity} ${material.unit}`,
          `€${(parseFloat(material.price) * parseFloat(material.quantity)).toFixed(2)}`
        ]);
      });

      selectedJobs.forEach(job => {
        const timeValue = job.timeUnit === 'hours' ? job.hours : job.days;
        const timeUnit = job.timeUnit === 'hours' ? 'ore' : 'giorni';
        const rate = job.timeUnit === 'hours' ? userSettings.hourlyRate : userSettings.dailyRate;
        tableRows.push([
          `Manodopera - ${job.name}`,
          `€${rate}/${timeUnit}`,
          `${timeValue} ${timeUnit}`,
          `€${(parseFloat(timeValue) * rate).toFixed(2)}`
        ]);
      });
    } else {
      // Aggregate materials
      const aggregatedMaterials = materials.reduce((acc, material) => {
        const existingMaterial = acc.find(m => m.name === material.name && m.unit === material.unit);
        if (existingMaterial) {
          existingMaterial.quantity += parseFloat(material.quantity);
        } else {
          acc.push({ ...material, quantity: parseFloat(material.quantity) });
        }
        return acc;
      }, []);

      aggregatedMaterials.forEach(material => {
        tableRows.push([
          material.name,
          `${material.quantity} ${material.unit}`
        ]);
      });

      // Aggregate jobs
      const aggregatedJobs = selectedJobs.reduce((acc, job) => {
        const existingJob = acc.find(j => j.name === job.name && j.timeUnit === job.timeUnit);
        if (existingJob) {
          existingJob.time += parseFloat(job.timeUnit === 'hours' ? job.hours : job.days);
        } else {
          acc.push({ 
            name: job.name,
            timeUnit: job.timeUnit, 
            time: parseFloat(job.timeUnit === 'hours' ? job.hours : job.days) 
          });
        }
        return acc;
      }, []);

      aggregatedJobs.forEach(job => {
        tableRows.push([
          `Manodopera - ${job.name}`,
          `${job.time} ${job.timeUnit === 'hours' ? 'ore' : 'giorni'}`
        ]);
      });
    }

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: y + 10,
      styles: { cellPadding: 2, fontSize: 10 },
      columnStyles: { 0: { cellWidth: 80 } }
    });

  
    const finalY = doc.lastAutoTable.finalY + 10;

    doc.text(`Subtotale: €${calculateSubtotal().toFixed(2)}`, 160, finalY);
    
    if (!userSettings.regimeForfettario) {
      doc.text(`IVA (22%): €${calculateTax().toFixed(2)}`, 160, finalY + 7);
      doc.text(`Totale: €${calculateTotal().toFixed(2)}`, 160, finalY + 14);
    } else {
      doc.text(`Totale: €${calculateSubtotal().toFixed(2)}`, 160, finalY + 7);
      doc.text(`Regime Forfettario - IVA non applicata`, 14, finalY + 14);
    }

    doc.save("preventivo.pdf");
  };

  const handleSaveQuote = async () => {
    if (!db) return;
    try {
      const quoteData = {
        userId: currentUser.uid,
        clientId: selectedClient,
        jobs: selectedJobs.map(job => ({ 
          id: job.id, 
          instanceId: job.instanceId,
          name: job.name,
          description: job.description,
          hours: job.hours, 
          days: job.days, 
          timeUnit: job.timeUnit 
        })),
        materials: materials,
        subtotal: calculateSubtotal(),
        tax: calculateTax(),
        total: calculateTotal(),
        showPrices: showPrices,
        regimeForfettario: userSettings.regimeForfettario,
        createdAt: new Date()
      };

      if (editingQuoteId) {
        await updateDoc(doc(db, 'quotes', editingQuoteId), quoteData);
        alert('Preventivo aggiornato con successo!');
      } else {
        await addDoc(collection(db, 'quotes'), quoteData);
        alert('Preventivo salvato con successo!');
      }
      navigate('/quotations');
    } catch (error) {
      console.error('Error saving quote:', error);
      setError('Unable to save quote. Please try again.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">
        {editingQuoteId ? 'Modifica Preventivo' : 'Generatore di Preventivi'}
      </h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="space-y-4">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Cerca clienti..."
          className="w-full p-2 border rounded mb-4"
        />
        <select
          value={selectedClient}
          onChange={(e) => setSelectedClient(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="">Seleziona un cliente</option>
          {filteredClients.map(client => (
            <option key={client.id} value={client.id}>
              {client.firstName} {client.lastName} - {client.companyName || 'N/A'}
            </option>
          ))}
        </select>
        <select
          onChange={(e) => handleJobSelect(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="">Aggiungi un lavoro</option>
          {jobs.map(job => (
            <option key={job.id} value={job.id}>{job.name}</option>
          ))}
        </select>
        {selectedJobs.map(job => (
          <div key={job.instanceId} className="flex flex-wrap items-center space-x-2 bg-gray-100 p-2 rounded mb-2">
            <div className="w-full mb-2">
              <span className="font-semibold">{job.name}</span>
              <p className="text-sm text-gray-600">{job.description}</p>
            </div>
            <div className="flex flex-wrap items-center space-x-2 w-full">
              <input
                type="number"
                value={job.timeUnit === 'hours' ? job.hours : job.days}
                onChange={(e) => handleJobTimeChange(job.instanceId, job.timeUnit === 'hours' ? 'hours' : 'days', e.target.value)}
                placeholder={job.timeUnit === 'hours' ? "Ore" : "Giorni"}
                className="w-20 p-2 border rounded"
              />
              <select
                value={job.timeUnit}
                onChange={(e) => handleJobTimeUnitChange(job.instanceId, e.target.value)}
                className="p-2 border rounded"
              >
                <option value="hours">Ore</option>
                <option value="days">Giorni</option>
              </select>
              <button onClick={() => handleDuplicateJob(job)} className="bg-blue-500 text-white px-2 py-1 rounded">Duplica</button>
              <button onClick={() => handleRemoveJob(job.instanceId)} className="bg-red-500 text-white px-2 py-1 rounded">Rimuovi</button>
            </div>
          </div>
        ))}
        <h3 className="font-semibold">Materiali</h3>
        {materials.map((mat, index) => (
          <div key={index} className="flex flex-wrap space-x-2 mb-2">
            <input
              value={mat.name}
              onChange={(e) => handleUpdateMaterial(index, 'name', e.target.value)}
              className="flex-grow p-2 border rounded mb-2 sm:mb-0"
              placeholder="Nome materiale"
            />
            <input
              type="number"
              value={mat.quantity}
              onChange={(e) => handleUpdateMaterial(index, 'quantity', e.target.value)}
              className="w-full sm:w-20 p-2 border rounded mb-2 sm:mb-0"
              placeholder="Quantità"
            />
            <input
              value={mat.unit}
              onChange={(e) => handleUpdateMaterial(index, 'unit', e.target.value)}
              className="w-full sm:w-20 p-2 border rounded mb-2 sm:mb-0"
              placeholder="Unità"
            />
            <input
              type="number"
              value={mat.price}
              onChange={(e) => handleUpdateMaterial(index, 'price', e.target.value)}
              className="w-full sm:w-24 p-2 border rounded mb-2 sm:mb-0"
              placeholder="Prezzo"
            />
            <button onClick={() => handleRemoveMaterial(index)} className="w-full sm:w-auto bg-red-500 text-white px-2 py-1 rounded">Rimuovi</button>
          </div>
        ))}
        <div className="grid grid-cols-1 sm:grid-cols-5 gap-2 mb-2">
          <input
            value={newMaterial.name}
            onChange={(e) => setNewMaterial({...newMaterial, name: e.target.value})}
            placeholder="Nome materiale"
            className="col-span-1 sm:col-span-2 p-2 border rounded"
          />
          <input
            type="number"
            value={newMaterial.quantity}
            onChange={(e) => setNewMaterial({...newMaterial, quantity: e.target.value})}
            placeholder="Quantità"
            className="p-2 border rounded"
          />
          <input
            value={newMaterial.unit}
            onChange={(e) => setNewMaterial({...newMaterial, unit: e.target.value})}
            placeholder="Unità"
            className="p-2 border rounded"
          />
          <input
            type="number"
            value={newMaterial.price}
            onChange={(e) => setNewMaterial({...newMaterial, price: e.target.value})}
            placeholder="Prezzo"
            className="p-2 border rounded"
          />
          <button onClick={handleAddMaterial} className="col-span-1 sm:col-span-5 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Aggiungi
          </button>
        </div>
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={showPrices}
            onChange={(e) => setShowPrices(e.target.checked)}
            id="showPrices"
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <label htmlFor="showPrices" className="text-gray-700">Mostra prezzi dettagliati nel preventivo</label>
        </div>
        <div className="mt-4">
          <p className="text-lg">Subtotale: €{calculateSubtotal().toFixed(2)}</p>
          {!userSettings.regimeForfettario && (
            <p className="text-lg">IVA (22%): €{calculateTax().toFixed(2)}</p>
          )}
          <p className="text-xl font-bold">Totale: €{calculateTotal().toFixed(2)}</p>
          {userSettings.regimeForfettario && (
            <p className="text-sm text-gray-600">Regime Forfettario - IVA non applicata</p>
          )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
          <button 
            onClick={generatePDF} 
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
          >
            Genera PDF
          </button>
          <button 
            onClick={handleSaveQuote} 
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
          >
            {editingQuoteId ? 'Aggiorna Preventivo' : 'Salva Preventivo'}
          </button>
          {editingQuoteId && (
            <button 
              onClick={() => navigate('/quotations')} 
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-300"
            >
              Annulla
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuoteGenerator;
