// src/components/ShoppingListGenerator.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { initializeFirebase, getDb } from '../firebase/config';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';

function ShoppingListGenerator() {
  const { currentUser } = useAuth();
  const [db, setDb] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedJob, setSelectedJob] = useState('');
  const [materials, setMaterials] = useState([]);
  const [newMaterial, setNewMaterial] = useState({ name: '', quantity: '', unit: '' });
  const [error, setError] = useState('');
  const [isFromQuote, setIsFromQuote] = useState(false);
  const [quoteId, setQuoteId] = useState('');
  const [userSettings, setUserSettings] = useState({});
  const [clientInfo, setClientInfo] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const setup = async () => {
      const { db } = await initializeFirebase();
      setDb(db);
    };
    setup();
  }, []);

  useEffect(() => {
    if (db && currentUser) {
      fetchJobs();
      fetchClients();
      fetchUserSettings();
      const searchParams = new URLSearchParams(location.search);
      const id = searchParams.get('id');
      if (id) {
        setIsFromQuote(true);
        setQuoteId(id);
        fetchQuoteData(id);
      }
    }
  }, [db, currentUser, location]);

  useEffect(() => {
    const filtered = clients.filter(client =>
      `${client.firstName} ${client.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.phone.includes(searchTerm) ||
      client.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredClients(filtered);
  }, [clients, searchTerm]);

  const fetchJobs = async () => {
    if (!db) return;
    try {
      const jobsQuery = query(collection(db, 'jobs'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(jobsQuery);
      const fetchedJobs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobs(fetchedJobs);
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setError('Unable to fetch jobs. Please try again.');
    }
  };

  const fetchClients = async () => {
    if (!db) return;
    try {
      const clientsQuery = query(collection(db, 'clients'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(clientsQuery);
      const fetchedClients = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setClients(fetchedClients);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError('Unable to fetch clients. Please try again.');
    }
  };

  const fetchUserSettings = async () => {
    if (!db) return;
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        setUserSettings(userDoc.data());
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
      setError('Unable to fetch user settings. Please try again.');
    }
  };

  const fetchQuoteData = async (quoteId) => {
    if (!db) return;
    try {
      const quoteRef = doc(db, 'quotes', quoteId);
      const quoteDoc = await getDoc(quoteRef);
      if (quoteDoc.exists()) {
        const quoteData = quoteDoc.data();
        setMaterials(quoteData.materials || []);
        setSelectedJob(quoteData.jobs[0]?.id || '');
        setSelectedClient(quoteData.clientId || '');
        
        if (quoteData.clientId) {
          const clientRef = doc(db, 'clients', quoteData.clientId);
          const clientDoc = await getDoc(clientRef);
          if (clientDoc.exists()) {
            setClientInfo(clientDoc.data());
          }
        }
      } else {
        setError('Quote not found.');
      }
    } catch (error) {
      console.error('Error fetching quote data:', error);
      setError('Unable to fetch quote data. Please try again.');
    }
  };

  const handleJobSelect = (jobId) => {
    setSelectedJob(jobId);
    const selectedJobData = jobs.find(job => job.id === jobId);
    if (selectedJobData && selectedJobData.defaultMaterials) {
      setMaterials(selectedJobData.defaultMaterials);
    } else {
      setMaterials([]);
    }
  };

  const handleAddMaterial = () => {
    if (newMaterial.name && newMaterial.quantity && newMaterial.unit) {
      setMaterials([...materials, newMaterial]);
      setNewMaterial({ name: '', quantity: '', unit: '' });
    }
  };

  const handleUpdateMaterial = (index, field, value) => {
    const updatedMaterials = [...materials];
    updatedMaterials[index][field] = value;
    setMaterials(updatedMaterials);
  };

  const handleRemoveMaterial = (index) => {
    const updatedMaterials = materials.filter((_, i) => i !== index);
    setMaterials(updatedMaterials);
  };

  const generatePDF = async () => {
    const doc = new jsPDF();
    const tableColumn = ["Descrizione", "Quantità", "Unità"];
    const tableRows = materials.map(material => [
      material.name,
      material.quantity,
      material.unit
    ]);

    // Add logo if available
    if (userSettings.logoDataUrl) {
      try {
        const img = new Image();
        img.src = userSettings.logoDataUrl;
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
        const aspectRatio = img.width / img.height;
        const maxWidth = 30;
        const width = Math.min(img.width, maxWidth);
        const height = width / aspectRatio;
        doc.addImage(userSettings.logoDataUrl, 'PNG', 14, 10, width, height);
      } catch (error) {
        console.error('Error adding logo to PDF:', error);
      }
    }

    // Add user data to top right corner
    doc.setFontSize(10);
    doc.setTextColor(100);
    const userDataX = 135;
    let userDataY = 15;

    // Add company name in bold
    doc.setFont(undefined, 'bold');
    doc.text(`${userSettings.companyName || 'Company Name Not Set'}`, userDataX, userDataY);
    doc.setFont(undefined, 'normal');

    doc.text(`P.IVA: ${userSettings.vatNumber || 'Not Set'}`, userDataX, userDataY += 5);
    doc.text(`Codice Fiscale: ${userSettings.fiscalCode || 'Not Set'}`, userDataX, userDataY += 5);
    doc.text(`${userSettings.address || ''}, ${userSettings.city || ''}`, userDataX, userDataY += 5);
    doc.text(`${userSettings.province || ''} ${userSettings.postalCode || ''}`, userDataX, userDataY += 5);
    doc.text(`Tel: ${userSettings.phone || ''}`, userDataX, userDataY += 5);
    doc.text(`Email: ${userSettings.email || ''}`, userDataX, userDataY += 5);

    // Reset text color and font size
    doc.setTextColor(0);
    doc.setFontSize(16);
    doc.text("Lista della Spesa", 14, 50);
    
    doc.setFontSize(12);
    let y = 60;
    doc.text(`Data: ${new Date().toLocaleDateString()}`, 14, y);
    
    const selectedClientData = clients.find(client => client.id === selectedClient);
    if (selectedClientData) {
      doc.text(`Cliente: ${selectedClientData.firstName} ${selectedClientData.lastName}`, 14, y += 10);
      doc.text(`Azienda: ${selectedClientData.companyName || 'N/A'}`, 14, y += 7);
      doc.text(`Indirizzo: ${selectedClientData.street}, ${selectedClientData.city}, ${selectedClientData.province} ${selectedClientData.postalCode}`, 14, y += 7);
      doc.text(`Tel: ${selectedClientData.phone}`, 14, y += 7);
      doc.text(`Email: ${selectedClientData.email}`, 14, y += 7);
    }
    
    if (quoteId) {
      doc.text(`Preventivo ID: ${quoteId}`, 14, y += 7);
    }

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: y + 10
    });

    doc.save("lista_della_spesa.pdf");
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Generatore Lista della Spesa</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {!isFromQuote && (
        <>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Cerca clienti..."
            className="w-full p-2 border rounded mb-4"
          />
          <select
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
            className="w-full p-2 border rounded mb-4"
          >
            <option value="">Seleziona un cliente</option>
            {filteredClients.map(client => (
              <option key={client.id} value={client.id}>
                {client.firstName} {client.lastName} - {client.companyName || 'N/A'}
              </option>
            ))}
          </select>
          <select
            value={selectedJob}
            onChange={(e) => handleJobSelect(e.target.value)}
            className="w-full p-2 border rounded mb-4"
          >
            <option value="">Seleziona un lavoro</option>
            {jobs.map(job => (
              <option key={job.id} value={job.id}>{job.name}</option>
            ))}
          </select>
        </>
      )}
      <div className="space-y-4">
        <h3 className="font-semibold">Materiali</h3>
        {materials.map((mat, index) => (
          <div key={index} className="flex space-x-2 mb-2">
            <input
              value={mat.name}
              onChange={(e) => handleUpdateMaterial(index, 'name', e.target.value)}
              className="flex-grow p-2 border rounded"
              placeholder="Nome materiale"
            />
            <input
              type="number"
              value={mat.quantity}
              onChange={(e) => handleUpdateMaterial(index, 'quantity', e.target.value)}
              className="w-20 p-2 border rounded"
              placeholder="Quantità"
            />
            <input
              value={mat.unit}
              onChange={(e) => handleUpdateMaterial(index, 'unit', e.target.value)}
              className="w-20 p-2 border rounded"
              placeholder="Unità"
            />
            <button onClick={() => handleRemoveMaterial(index)} className="bg-red-500 text-white px-2 py-1 rounded">Rimuovi</button>
          </div>
        ))}
        <div className="flex space-x-2 mb-2">
          <input
            value={newMaterial.name}
            onChange={(e) => setNewMaterial({...newMaterial, name: e.target.value})}
            placeholder="Nome materiale"
            className="flex-grow p-2 border rounded"
          />
          <input
            type="number"
            value={newMaterial.quantity}
            onChange={(e) => setNewMaterial({...newMaterial, quantity: e.target.value})}
            placeholder="Quantità"
            className="w-20 p-2 border rounded"
          />
          <input
            value={newMaterial.unit}
            onChange={(e) => setNewMaterial({...newMaterial, unit: e.target.value})}
            placeholder="Unità"
            className="w-20 p-2 border rounded"
          />
          <button onClick={handleAddMaterial} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Aggiungi
          </button>
        </div>
        <button 
          onClick={generatePDF} 
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Genera PDF Lista della Spesa
        </button>
      </div>
    </div>
  );
}

export default ShoppingListGenerator;