import React, { useState } from 'react';
import GlobalHelmet from './GlobalHelmet';
import { ArrowRight, CheckCircle, Send, Users, FileText, Briefcase, UserCheck, ShoppingCart, ClipboardList, Calendar, Phone } from 'lucide-react';
import { inviaRichiestaInformazioni } from '../utils/emailService';

const LandingPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [formStatus, setFormStatus] = useState('');

  // Array of features reflecting actual Epeo functionalities
  const features = [
    {
      title: "Gestione Dipendenti",
      description: "Gestisci efficacemente il tuo team con strumenti avanzati. Aggiungi dipendenti, assegna compiti e monitora le prestazioni per ottimizzare la produttività.",
      icon: Users,
      highlighted: true
    },
    {
      title: "Area Dipendenti",
      description: "Offri ai tuoi dipendenti un'area personale intuitiva dove possono visualizzare i loro incarichi, registrare le ore di lavoro e gestire le attività assegnate.",
      icon: UserCheck,
      highlighted: true
    },
    {
      title: "Assegnazione Compiti",
      description: "Assegna facilmente compiti ai tuoi dipendenti, specificando dettagli come data di esecuzione, materiali necessari e note specifiche per ogni incarico.",
      icon: ClipboardList,
      highlighted: true
    },
    {
      title: "Gestione Clienti",
      description: "Centralizza tutte le informazioni dei tuoi clienti in un unico posto. Gestisci dettagli di contatto, cronologia dei lavori e preferenze per un servizio personalizzato.",
      icon: Users
    },
    {
      title: "Creazione Preventivi",
      description: "Genera preventivi professionali in pochi clic. Calcola automaticamente i costi, includi materiali e manodopera, e invia direttamente ai clienti.",
      icon: FileText
    },
    {
      title: "Gestione Lavori",
      description: "Tieni traccia di tutti i tuoi progetti. Crea e gestisci lavori, definisci materiali predefiniti e monitora lo stato di avanzamento di ogni attività.",
      icon: Briefcase
    },
    {
      title: "Lista della Spesa",
      description: "Genera automaticamente liste della spesa basate sui materiali necessari per i tuoi progetti. Ottimizza gli acquisti e mantieni sotto controllo l'inventario.",
      icon: ShoppingCart
    },
    {
      title: "Panoramica Assegnazioni",
      description: "Visualizza e gestisci tutte le assegnazioni di lavoro in un'unica interfaccia. Monitora lo stato, i tempi di esecuzione e l'efficienza del team.",
      icon: ClipboardList
    }
  ];

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus('sending');
    try {
      await inviaRichiestaInformazioni(
        'info@giallointeractive.com', // Replace with your company's contact email
        formData.name,
        formData.email,
        formData.message
      );
      setFormStatus('success');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error sending email:', error);
      setFormStatus('error');
    }
  };

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <GlobalHelmet 
        title="Epeo - Ottimizza la Gestione della Tua Azienda"
        description="Scopri come Epeo può rivoluzionare la gestione del personale, dei clienti e dei progetti della tua azienda. Contattaci per una demo personalizzata!"
      />

      <main className="flex-grow">
        {/* Hero Section */}
        <section className="bg-green-100 py-20">
          <div className="container mx-auto px-4">
            <div className="flex flex-col lg:flex-row items-center">
              <div className="lg:w-1/2 mb-8 lg:mb-0 flex flex-col items-center lg:items-start">
                <img 
                  src="logo.png" 
                  alt="Epeo Logo" 
                  className="w-[300px] h-[300px] mb-8 object-contain" 
                />
                <h1 className="text-4xl font-bold mb-4 text-yellow-500 text-center lg:text-left">Benvenuto in Epeo</h1>
                <p className="text-xl mb-8 text-green-700 text-center lg:text-left">La soluzione completa per la gestione del personale, clienti e progetti del tuo business</p>
                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                  <a href="#contattaci" className="bg-yellow-400 text-white py-2 px-6 rounded-full font-semibold hover:bg-yellow-500 transition duration-300 flex items-center justify-center">
                    <Phone className="mr-2" size={18} />
                    Contattaci
                  </a>
                  <a href="#demo" className="bg-green-500 text-white py-2 px-6 rounded-full font-semibold hover:bg-green-600 transition duration-300 flex items-center justify-center">
                    <Calendar className="mr-2" size={18} />
                    Prenota una Demo
                  </a>
                </div>
              </div>
              <div className="lg:w-1/2 mt-8 lg:mt-0">
                <img src="dashboard.png" alt="Epeo Dashboard" className="rounded-lg shadow-lg w-full" />
              </div>
            </div>
          </div>
        </section>

        {/* Features Overview Section */}
        <section className="py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-semibold text-center mb-12 text-green-700">Funzionalità Principali</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <div key={index} className={`flex items-start p-4 rounded-lg ${feature.highlighted ? 'bg-yellow-100 border-2 border-yellow-400' : 'bg-green-50'}`}>
                  <CheckCircle className={`${feature.highlighted ? 'text-yellow-500' : 'text-green-500'} mr-3 mt-1`} />
                  <p className={`text-lg ${feature.highlighted ? 'text-yellow-700 font-semibold' : 'text-green-700'}`}>{feature.title}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Detailed Features Section */}
        <section className="py-16 bg-green-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-semibold text-center mb-12 text-green-700">Scopri le Funzionalità di Epeo</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {features.map((feature, index) => (
                <div key={index} className={`p-6 rounded-lg shadow-lg ${feature.highlighted ? 'bg-yellow-50 border-2 border-yellow-400' : 'bg-white'}`}>
                  <div className="flex items-center mb-4">
                    <feature.icon className={`${feature.highlighted ? 'text-yellow-500' : 'text-green-500'} mr-3`} size={24} />
                    <h3 className={`text-xl font-semibold ${feature.highlighted ? 'text-yellow-700' : 'text-green-700'}`}>{feature.title}</h3>
                  </div>
                  <p className={`${feature.highlighted ? 'text-yellow-600' : 'text-green-600'}`}>{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Employee Management Highlight Section */}
        <section className="bg-yellow-100 py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-semibold text-center mb-8 text-yellow-700">Gestione del Personale Semplificata</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold text-yellow-600 mb-4">Assegnazione Incarichi</h3>
                <p className="text-yellow-700">Distribuisci facilmente gli incarichi ai tuoi dipendenti e monitora il loro avanzamento in tempo reale.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold text-yellow-600 mb-4">Monitoraggio Attività</h3>
                <p className="text-yellow-700">Tieni traccia delle attività assegnate, dei tempi di esecuzione e dello stato di completamento di ogni incarico.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold text-yellow-600 mb-4">Area Dipendenti</h3>
                <p className="text-yellow-700">Offri ai tuoi dipendenti un'interfaccia dedicata per gestire i propri incarichi e registrare le ore di lavoro.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Form Section */}
        <section id="contattaci" className="bg-green-100 py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-semibold text-center mb-8 text-green-700">Contattaci</h2>
            <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
              <div className="mb-4">
                <label htmlFor="name" className="block text-green-700 mb-2">Nome</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-green-300 rounded focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-green-700 mb-2">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-green-300 rounded focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block text-green-700 mb-2">Messaggio</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  rows="4"
                  className="w-full p-2 border border-green-300 rounded focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-yellow-400 text-white py-2 px-6 rounded-full font-semibold hover:bg-yellow-500 transition duration-300 flex items-center justify-center w-full"
                disabled={formStatus === 'sending'}
              >
                {formStatus === 'sending' ? 'Invio in corso...' : 'Invia'}
                <Send className="ml-2" size={18} />
              </button>
              {formStatus === 'success' && (
                <p className="text-green-600 mt-2">Messaggio inviato con successo!</p>
              )}
              {formStatus === 'error' && (
                <p className="text-red-600 mt-2">Si è verificato un errore. Riprova più tardi.</p>
              )}
            </form>
          </div>
        </section>

        {/* Demo Scheduling Section */}
        <section id="demo" className="py-16">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-semibold mb-4 text-green-700">Scopri Epeo in Azione</h2>
            <p className="text-xl mb-8 text-green-600">Prenota una demo personalizzata e scopri come Epeo può trasformare la gestione del personale, clienti e progetti della tua azienda</p>
            <a 
              href="https://calendly.com/lambertob/30min" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="bg-yellow-400 text-white py-3 px-8 rounded-full font-semibold hover:bg-yellow-500 transition duration-300 inline-flex items-center"
            >
              Prenota una Demo
              <Calendar className="ml-2" size={18} />
            </a>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-100 py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center mb-6">
            <div className="flex items-center mb-4 md:mb-0">
              <img src="logo.png" alt="Epeo Logo" className="h-24 w-24 mr-4" />
              <img src="https://www.giallointeractive.com/wp-content/uploads/2019/12/footer.png" alt="Giallo Interactive Logo" className="h-12" />
            </div>
            <div className="text-center md:text-right">
              <p className="text-lg font-semibold text-green-700 mb-2">Epeo è realizzato da Giallo Interactive</p>
              <a href="https://www.giallointeractive.it" target="_blank" rel="noopener noreferrer" className="text-yellow-500 hover:text-yellow-600 transition duration-300">
                www.giallointeractive.it
              </a>
            </div>
          </div>
          <div className="text-sm text-gray-600 text-center md:text-left">
            <p>Sede legale: Via Mercalli 14, 20019 Settimo Milanese (MI)</p>
            <p>P.IVA 03635020120 – Iscrizione C.C.I.A.A. di Milano – REA n. MI-2566992</p>
            <p>PEC: amministrazione@pec.giallointeractive.it</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;