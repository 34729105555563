// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import { signInWithPopup, signInWithEmailAndPassword, signOut as firebaseSignOut } from 'firebase/auth';
import { initializeFirebase, getAuthInstance, getDbInstance } from '../firebase/config';
import { collection, query, where, getDocs, doc, setDoc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [firebaseInstance, setFirebaseInstance] = useState(null);

  useEffect(() => {
    const initAuth = async () => {
      try {
        console.log('Initializing Firebase...');
        const instance = await initializeFirebase();
        setFirebaseInstance(instance);
        console.log('Firebase initialized successfully');
        
        const auth = getAuthInstance();
        const db = getDbInstance();
        
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          setLoading(true);
          console.log('Auth state changed. User:', user ? user.uid : 'null');
          if (user) {
            try {
              console.log('Checking if user is an employee...');
              const employeesRef = collection(db, 'employees');
              const q = query(employeesRef, where("email", "==", user.email));
              const querySnapshot = await getDocs(q);
              console.log('Employee query result:', querySnapshot.size);

              if (!querySnapshot.empty) {
                console.log('User is an employee');
                const employeeDoc = querySnapshot.docs[0];
                const employeeData = employeeDoc.data();
                setCurrentUser({ 
                  ...user, 
                  ...employeeData, 
                  role: 'employee'
                });
              } else {
                console.log('User is not an employee, checking users collection...');
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                
                if (!userDoc.exists()) {
                  console.log('User document does not exist, creating new user...');
                  await setDoc(userDocRef, {
                    email: user.email,
                    role: 'user',
                    canManageEmployees: true
                  });
                  console.log('New user document created');
                }
                
                const userData = userDoc.exists() ? userDoc.data() : { role: 'user', canManageEmployees: true };
                console.log('Setting current user with data:', userData);
                setCurrentUser({ ...user, ...userData });
              }
            } catch (error) {
              console.error('Error fetching user data:', error);
              setCurrentUser({ ...user, role: 'user' });
            }
          } else {
            console.log('No user, setting currentUser to null');
            setCurrentUser(null);
          }
          setLoading(false);
        });

        return unsubscribe;
      } catch (error) {
        console.error('Error initializing Firebase:', error);
        setLoading(false);
      }
    };

    initAuth();
  }, []);

  const signInWithGoogle = async () => {
    const auth = getAuthInstance();
    if (!auth || !firebaseInstance.googleProvider) {
      console.error('Firebase Auth not initialized or Google Provider not available');
      return;
    }
    try {
      console.log('Attempting Google sign in...');
      const result = await signInWithPopup(auth, firebaseInstance.googleProvider);
      console.log('Google sign in successful:', result.user.uid);
      return result.user;
    } catch (error) {
      console.error('Google sign in error:', error.code, error.message);
      throw error;
    }
  };

  const signInWithEmail = async (email, password) => {
    const auth = getAuthInstance();
    if (!auth) {
      console.error('Firebase Auth not initialized');
      return;
    }
    try {
      console.log('Attempting email sign in...');
      const result = await signInWithEmailAndPassword(auth, email, password);
      console.log('Email sign in successful:', result.user.uid);
      return result.user;
    } catch (error) {
      console.error('Email sign in error:', error.code, error.message);
      throw error;
    }
  };

  const signOut = async () => {
    const auth = getAuthInstance();
    if (!auth) {
      console.error('Firebase Auth not initialized');
      return;
    }
    try {
      console.log('Attempting sign out...');
      await firebaseSignOut(auth);
      console.log('Sign out successful');
    } catch (error) {
      console.error('Sign out error:', error.code, error.message);
      throw error;
    }
  };

  const value = {
    currentUser,
    loading,
    signInWithGoogle,
    signInWithEmail,
    signOut,
    firebaseInstance
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}