const EmailTemplates = {
  credenziali: ({ firstName, username, password, url }) => `
    <h1>Benvenuto in Azienda, ${firstName}!</h1>
    <p>Siamo lieti di averti a bordo. Ecco le tue credenziali di accesso:</p>
    <p><strong>Nome utente:</strong> ${username}</p>
    <p><strong>Password temporanea:</strong> ${password}</p>
    <a href="${url}">Accedi al tuo account</a>
    <p>Se hai domande, non esitare a contattare l'ufficio Risorse Umane.</p>
  `,

  benvenuto: ({ firstName, companyName, welcomeUrl }) => `
    <h1>Benvenuto in ${companyName}, ${firstName}!</h1>
    <p>Siamo entusiasti di averti con noi. Ecco alcune informazioni per iniziare:</p>
    <ul>
      <li>Esplora la nostra piattaforma</li>
      <li>Completa il tuo profilo</li>
      <li>Connettiti con il tuo team</li>
    </ul>
    <a href="${welcomeUrl}">Inizia ora</a>
    <p>Se hai domande, il nostro team di supporto è qui per aiutarti.</p>
    <p>Buon lavoro!<br/>Il team di ${companyName}</p>
  `,

  promemoria: ({ firstName, eventName, eventDate, eventUrl }) => `
    <h1>Promemoria: ${eventName}</h1>
    <p>Ciao ${firstName},</p>
    <p>Questo è un promemoria per l'evento "${eventName}" che si terrà il ${eventDate}.</p>
    <p>Non dimenticare di partecipare!</p>
    <a href="${eventUrl}">Dettagli evento</a>
    <p>Se hai bisogno di assistenza, contattaci.</p>
    <p>A presto!<br/>Il team Eventi</p>
  `,

  richiestaInformazioni: ({ name, email, message, companyName }) => `
    <h1>Nuova richiesta di informazioni</h1>
    <p>Hai ricevuto una nuova richiesta di informazioni dal sito web di ${companyName}.</p>
    <h2>Dettagli del contatto:</h2>
    <p><strong>Nome:</strong> ${name}</p>
    <p><strong>Email:</strong> ${email}</p>
    <h2>Messaggio:</h2>
    <p>${message}</p>
    <p>Per favore, rispondi a questa richiesta il prima possibile.</p>
    <p>Cordiali saluti,<br/>Il team di ${companyName}</p>
  `
};

export default EmailTemplates;