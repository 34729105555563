// src/components/EmployeeDashboard.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { initializeFirebase } from '../firebase/config';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { Link } from 'react-router-dom';

function EmployeeDashboard() {
  // Access the current user from the authentication context
  const { currentUser } = useAuth();
  
  // State to store the user's assignments
  const [assignments, setAssignments] = useState([]);
  
  // State to manage loading status
  const [loading, setLoading] = useState(true);
  
  // State to handle any errors
  const [error, setError] = useState('');
  
  // State to manage visibility of details for each assignment
  const [visibilityState, setVisibilityState] = useState({});

  // Effect hook to fetch assignments when the component mounts
  useEffect(() => {
    if (currentUser) {
      fetchAssignments();
    }
  }, [currentUser]);

  // Function to fetch assignments from Firestore
  const fetchAssignments = async () => {
    try {
      const { db } = await initializeFirebase();
      
      console.log('Fetching assignments for user:', currentUser.uid);
      
      // Get the start and end of the current day
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      // Create a query to get assignments for the current user, for today only, ordered by execution date
      const assignmentsQuery = query(
        collection(db, 'assignments'),
        where('employeeId', '==', currentUser.uid),
        where('executionDate', '>=', today),
        where('executionDate', '<', tomorrow),
        orderBy('executionDate', 'asc')
      );
      
      const querySnapshot = await getDocs(assignmentsQuery);
      console.log('Query snapshot size:', querySnapshot.size);
      
      // Map the query results to our assignments state, excluding completed assignments
      const fetchedAssignments = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter(assignment => assignment.status !== 'completed'); // Exclude completed assignments

      console.log('Fetched assignments:', fetchedAssignments);
      
      setAssignments(fetchedAssignments);

      // Initialize visibility state for each assignment
      const initialVisibilityState = {};
      fetchedAssignments.forEach(assignment => {
        initialVisibilityState[assignment.id] = { 
          jobs: false, 
          materials: false, 
          notes: false 
        };
      });
      setVisibilityState(initialVisibilityState);
    } catch (error) {
      console.error('Error fetching assignments:', error);
      setError('Failed to load assignments. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Function to format date to a readable string
  const formatDate = (date) => {
    return new Date(date.seconds * 1000).toLocaleString('it-IT', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Function to get the status in Italian
  const getStatusInItalian = (status) => {
    switch (status) {
      case 'assigned': return 'Assegnato';
      case 'in progress': return 'In Corso';
      case 'completed': return 'Completato';
      default: return status;
    }
  };

  // Function to toggle visibility of different sections
  const toggleVisibility = (assignmentId, field) => {
    setVisibilityState(prevState => ({
      ...prevState,
      [assignmentId]: {
        ...prevState[assignmentId],
        [field]: !prevState[assignmentId][field]
      }
    }));
  };

  // Show loading state if data is still being fetched
  if (loading) return <div className="p-4">Caricamento...</div>;
  
  // Show error message if there was an error fetching data
  if (error) return <div className="p-4 text-red-500">Errore: {error}</div>;

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Benvenuto, {currentUser.displayName}</h1>
      
      {/* Employee Information Section */}
      <div className="bg-white shadow rounded-lg p-6 mb-4">
        <h2 className="text-xl font-semibold mb-2">Le Tue Informazioni</h2>
        <p>Email: {currentUser.email}</p>
        <p>Ruolo: {currentUser.role || 'Dipendente'}</p>
      </div>
      
      {/* Assignments Section */}
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-2">I Tuoi Incarichi di Oggi</h2>
        {assignments.length === 0 ? (
          <p>Nessun incarico per oggi.</p>
        ) : (
          <ul className="space-y-4">
            {assignments.map(assignment => (
              <li key={assignment.id} className="border-b pb-4">
                <p><strong>Tipo:</strong> {assignment.taskType === 'job' ? 'Lavoro' : 'Preventivo'}</p>
                <p><strong>Cliente:</strong> {assignment.customerName}</p>
                <p><strong>Indirizzo:</strong> {assignment.customerAddress}</p>
                <p><strong>Telefono:</strong> {assignment.customerPhone}</p>
                <p><strong>Data e Ora di Esecuzione:</strong> {formatDate(assignment.executionDate)}</p>
                <p><strong>Stato:</strong> {getStatusInItalian(assignment.status)}</p>
                
                {/* Toggle button for notes */}
                <button 
                  onClick={() => toggleVisibility(assignment.id, 'notes')}
                  className="mt-2 bg-blue-500 text-white px-2 py-1 rounded text-sm"
                >
                  {visibilityState[assignment.id]?.notes ? 'Nascondi Note' : 'Mostra Note'}
                </button>
                {visibilityState[assignment.id]?.notes && (
                  <p><strong>Note:</strong> {assignment.notes || 'N/A'}</p>
                )}
                
                {/* Toggle button for jobs */}
                <button 
                  onClick={() => toggleVisibility(assignment.id, 'jobs')}
                  className="mt-2 ml-2 bg-green-500 text-white px-2 py-1 rounded text-sm"
                >
                  {visibilityState[assignment.id]?.jobs ? 'Nascondi Lavori' : 'Mostra Lavori'}
                </button>
                {visibilityState[assignment.id]?.jobs && assignment.jobs && (
                  <div className="mt-2">
                    <h4 className="font-semibold">Lavori:</h4>
                    <ul className="list-disc list-inside">
                      {assignment.jobs.map((job, index) => (
                        <li key={index}>{job.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
                
                {/* Toggle button for materials */}
                <button 
                  onClick={() => toggleVisibility(assignment.id, 'materials')}
                  className="mt-2 ml-2 bg-yellow-500 text-white px-2 py-1 rounded text-sm"
                >
                  {visibilityState[assignment.id]?.materials ? 'Nascondi Materiali' : 'Mostra Materiali'}
                </button>
                {visibilityState[assignment.id]?.materials && (
                  <div className="mt-2">
                    <h4 className="font-semibold">Materiali:</h4>
                    {assignment.jobs ? (
                      // If jobs are present, show materials for each job
                      assignment.jobs.map((job, jobIndex) => (
                        <div key={jobIndex}>
                          <h5 className="font-medium">{job.name}:</h5>
                          <ul className="list-disc list-inside ml-4">
                            {job.materials.map((material, materialIndex) => (
                              <li key={materialIndex}>
                                {material.name} - {material.quantity} {material.unit}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))
                    ) : (
                      // If no jobs, show materials directly
                      <ul className="list-disc list-inside">
                        {assignment.materials.map((material, index) => (
                          <li key={index}>{material.name} - {material.quantity} {material.unit}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
      
      {/* Link to view all assignments */}
      <Link to="/employee-area/assignments" className="mt-4 inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
        Visualizza Tutti gli Incarichi
      </Link>
    </div>
  );
}

export default EmployeeDashboard;