// src/components/ClientManagement.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { initializeFirebase } from '../firebase/config';
import { collection, addDoc, query, where, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';

function ClientManagement() {
  const { currentUser } = useAuth();
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [newClient, setNewClient] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    vatNumber: '',  // P.IVA
    fiscalCode: '', // Codice Fiscale
    street: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
    phone: '',
    email: ''
  });
  const [editingClient, setEditingClient] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [db, setDb] = useState(null);

  useEffect(() => {
    const setup = async () => {
      const { db } = await initializeFirebase();
      setDb(db);
    };
    setup();
  }, []);

  useEffect(() => {
    if (db && currentUser) {
      fetchClients();
    }
  }, [db, currentUser]);

  useEffect(() => {
    const filtered = clients.filter(client =>
      `${client.firstName} ${client.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.phone.includes(searchTerm) ||
      client.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredClients(filtered);
  }, [clients, searchTerm]);

  const fetchClients = async () => {
    if (!db) return;
    try {
      const clientsQuery = query(collection(db, 'clients'), where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(clientsQuery);
      const fetchedClients = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setClients(fetchedClients);
    } catch (error) {
      console.error('Errore nel recupero dei clienti:', error);
      setError('Impossibile recuperare i clienti. Per favore riprova.');
    }
  };

  const handleAddClient = async (e) => {
    e.preventDefault();
    if (!db) return;
    try {
      await addDoc(collection(db, 'clients'), {
        userId: currentUser.uid,
        ...newClient,
        createdAt: new Date()
      });
      setNewClient({
        firstName: '',
        lastName: '',
        companyName: '',
        vatNumber: '',
        fiscalCode: '',
        street: '',
        city: '',
        province: '',
        postalCode: '',
        country: '',
        phone: '',
        email: ''
      });
      fetchClients();
    } catch (error) {
      console.error('Errore nell\'aggiunta del nuovo cliente:', error);
      setError('Impossibile aggiungere il nuovo cliente. Per favore riprova.');
    }
  };

  const handleEditClient = async (e) => {
    e.preventDefault();
    if (!db || !editingClient) return;
    try {
      await updateDoc(doc(db, 'clients', editingClient.id), {
        firstName: editingClient.firstName,
        lastName: editingClient.lastName,
        companyName: editingClient.companyName,
        vatNumber: editingClient.vatNumber,
        fiscalCode: editingClient.fiscalCode,
        street: editingClient.street,
        city: editingClient.city,
        province: editingClient.province,
        postalCode: editingClient.postalCode,
        country: editingClient.country,
        phone: editingClient.phone,
        email: editingClient.email,
      });
      setEditingClient(null);
      fetchClients();
    } catch (error) {
      console.error('Errore nell\'aggiornamento del cliente:', error);
      setError('Impossibile aggiornare il cliente. Per favore riprova.');
    }
  };

  const handleDeleteClient = async (clientId) => {
    if (!db) return;
    try {
      await deleteDoc(doc(db, 'clients', clientId));
      fetchClients();
    } catch (error) {
      console.error('Errore nell\'eliminazione del cliente:', error);
      setError('Impossibile eliminare il cliente. Per favore riprova.');
    }
  };

  // Helper function to render input fields
  const renderInput = (label, name, value, onChange, type = "text") => (
    <div className="mb-2">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        required
      />
    </div>
  );

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Gestione Clienti</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Cerca clienti..."
        className="w-full p-2 border rounded mb-4"
      />

      <form onSubmit={editingClient ? handleEditClient : handleAddClient} className="mb-8 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          {renderInput("Nome", "firstName", editingClient ? editingClient.firstName : newClient.firstName, 
            (e) => editingClient ? setEditingClient({...editingClient, firstName: e.target.value}) : setNewClient({...newClient, firstName: e.target.value}))}
          
          {renderInput("Cognome", "lastName", editingClient ? editingClient.lastName : newClient.lastName,
            (e) => editingClient ? setEditingClient({...editingClient, lastName: e.target.value}) : setNewClient({...newClient, lastName: e.target.value}))}
          
          {renderInput("Nome Azienda", "companyName", editingClient ? editingClient.companyName : newClient.companyName,
            (e) => editingClient ? setEditingClient({...editingClient, companyName: e.target.value}) : setNewClient({...newClient, companyName: e.target.value}))}
          
          {renderInput("P.IVA", "vatNumber", editingClient ? editingClient.vatNumber : newClient.vatNumber,
            (e) => editingClient ? setEditingClient({...editingClient, vatNumber: e.target.value}) : setNewClient({...newClient, vatNumber: e.target.value}))}
          
          {renderInput("Codice Fiscale", "fiscalCode", editingClient ? editingClient.fiscalCode : newClient.fiscalCode,
            (e) => editingClient ? setEditingClient({...editingClient, fiscalCode: e.target.value}) : setNewClient({...newClient, fiscalCode: e.target.value}))}
          
          {renderInput("Via", "street", editingClient ? editingClient.street : newClient.street,
            (e) => editingClient ? setEditingClient({...editingClient, street: e.target.value}) : setNewClient({...newClient, street: e.target.value}))}
          
          {renderInput("Città", "city", editingClient ? editingClient.city : newClient.city,
            (e) => editingClient ? setEditingClient({...editingClient, city: e.target.value}) : setNewClient({...newClient, city: e.target.value}))}
          
          {renderInput("Provincia", "province", editingClient ? editingClient.province : newClient.province,
            (e) => editingClient ? setEditingClient({...editingClient, province: e.target.value}) : setNewClient({...newClient, province: e.target.value}))}
          
          {renderInput("CAP", "postalCode", editingClient ? editingClient.postalCode : newClient.postalCode,
            (e) => editingClient ? setEditingClient({...editingClient, postalCode: e.target.value}) : setNewClient({...newClient, postalCode: e.target.value}))}
          
          {renderInput("Paese", "country", editingClient ? editingClient.country : newClient.country,
            (e) => editingClient ? setEditingClient({...editingClient, country: e.target.value}) : setNewClient({...newClient, country: e.target.value}))}
          
          {renderInput("Telefono", "phone", editingClient ? editingClient.phone : newClient.phone,
            (e) => editingClient ? setEditingClient({...editingClient, phone: e.target.value}) : setNewClient({...newClient, phone: e.target.value}), "tel")}
          
          {renderInput("Email", "email", editingClient ? editingClient.email : newClient.email,
            (e) => editingClient ? setEditingClient({...editingClient, email: e.target.value}) : setNewClient({...newClient, email: e.target.value}), "email")}
        </div>
        
        <div className="flex items-center justify-between">
          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            {editingClient ? 'Aggiorna Cliente' : 'Aggiungi Cliente'}
          </button>
          {editingClient && (
            <button
              type="button"
              onClick={() => setEditingClient(null)}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Annulla Modifica
            </button>
          )}
        </div>
      </form>

      <div className="space-y-4">
        {filteredClients.map(client => (
          <div key={client.id} className="border p-4 rounded bg-white shadow">
            <h2 className="text-xl font-semibold">{client.firstName} {client.lastName}</h2>
            {client.companyName && <p className="text-gray-600">Azienda: {client.companyName}</p>}
            <p>P.IVA: {client.vatNumber}</p>
            <p>Codice Fiscale: {client.fiscalCode}</p>
            <p>Indirizzo: {client.street}, {client.city}, {client.province} {client.postalCode}, {client.country}</p>
            <p>Tel: {client.phone}</p>
            <p>Email: {client.email}</p>
            <div className="mt-2">
              <button
                onClick={() => setEditingClient(client)}
                className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 mr-2"
              >
                Modifica
              </button>
              <button
                onClick={() => handleDeleteClient(client.id)}
                className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
              >
                Elimina
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClientManagement;