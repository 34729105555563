// src/components/EmployeeAssignments.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { initializeFirebase } from '../firebase/config';
import { collection, query, where, getDocs, updateDoc, doc, orderBy, Timestamp } from 'firebase/firestore';

function EmployeeAssignments() {
  const { currentUser } = useAuth();
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [visibilityState, setVisibilityState] = useState({});

  useEffect(() => {
    if (currentUser) {
      fetchAssignments();
    }
  }, [currentUser]);

  const fetchAssignments = async () => {
    try {
      const { db } = await initializeFirebase();
      const assignmentsQuery = query(
        collection(db, 'assignments'),
        where('employeeId', '==', currentUser.uid),
        orderBy('executionDate', 'asc')
      );
      const querySnapshot = await getDocs(assignmentsQuery);
      const fetchedAssignments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAssignments(fetchedAssignments);

      const initialVisibilityState = {};
      fetchedAssignments.forEach(assignment => {
        initialVisibilityState[assignment.id] = { 
          jobs: false, 
          materials: false, 
          notes: false 
        };
      });
      setVisibilityState(initialVisibilityState);
    } catch (error) {
      console.error('Error fetching assignments:', error);
      setError('Failed to load assignments. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (assignmentId, newStatus) => {
    try {
      const { db } = await initializeFirebase();
      const assignmentRef = doc(db, 'assignments', assignmentId);
      const assignment = assignments.find(a => a.id === assignmentId);
      const updateData = { status: newStatus };

      const now = Timestamp.now();

      if (newStatus === 'in progress') {
        // If the assignment is being started now, use the current time
        updateData.startTime = now;
      } else if (newStatus === 'completed') {
        // If completing the assignment, calculate elapsed time
        let startTime;
        if (assignment.startTime) {
          // If there's a recorded start time, use it
          startTime = assignment.startTime.toDate();
        } else if (assignment.executionDate) {
          // If no start time, use the execution date
          startTime = assignment.executionDate.toDate();
        } else {
          console.error('No valid start time found for assignment:', assignmentId);
          setError('Unable to calculate elapsed time. No valid start time found.');
          return;
        }

        const endTime = now.toDate();
        const elapsedMilliseconds = endTime - startTime;
        const elapsedMinutes = Math.round(elapsedMilliseconds / 60000); // Convert to minutes

        updateData.endTime = now;
        updateData.elapsedTime = elapsedMinutes;
      }

      await updateDoc(assignmentRef, updateData);
      fetchAssignments(); // Refresh assignments after update
    } catch (error) {
      console.error('Error updating assignment status:', error);
      setError('Failed to update assignment status. Please try again.');
    }
  };

  const toggleVisibility = (assignmentId, field) => {
    setVisibilityState(prevState => ({
      ...prevState,
      [assignmentId]: {
        ...prevState[assignmentId],
        [field]: !prevState[assignmentId][field]
      }
    }));
  };

  const formatDate = (date) => {
    return new Date(date.seconds * 1000).toLocaleString('it-IT', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getStatusInItalian = (status) => {
    switch (status) {
      case 'assigned': return 'Assegnato';
      case 'in progress': return 'In Corso';
      case 'completed': return 'Completato';
      default: return status;
    }
  };

  // Function to format elapsed time
  const formatElapsedTime = (minutes) => {
    if (minutes < 60) {
      return `${minutes} minuti`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} ore e ${remainingMinutes} minuti`;
  };

  // Function to calculate and format current elapsed time
  const getCurrentElapsedTime = (assignment) => {
    let startTime;
    if (assignment.startTime) {
      startTime = assignment.startTime.toDate();
    } else if (assignment.executionDate) {
      startTime = assignment.executionDate.toDate();
    } else {
      return 'Tempo di inizio non disponibile';
    }

    const now = new Date();
    const elapsedMilliseconds = now - startTime;
    const elapsedMinutes = Math.round(elapsedMilliseconds / 60000);
    return formatElapsedTime(elapsedMinutes);
  };

  if (loading) return <div className="p-4">Caricamento...</div>;
  if (error) return <div className="p-4 text-red-500">Errore: {error}</div>;

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">I Tuoi Incarichi</h1>
      {assignments.length === 0 ? (
        <p>Nessun incarico attuale.</p>
      ) : (
        <ul className="space-y-4">
          {assignments.map(assignment => (
            <li key={assignment.id} className="bg-white shadow rounded-lg p-4">
              <p><strong>Tipo:</strong> {assignment.taskType === 'job' ? 'Lavoro' : 'Preventivo'}</p>
              <p><strong>Cliente:</strong> {assignment.customerName}</p>
              <p><strong>Indirizzo:</strong> {assignment.customerAddress}</p>
              <p><strong>Telefono:</strong> {assignment.customerPhone}</p>
              <p><strong>Data e Ora dell'appuntamento:</strong> {formatDate(assignment.executionDate)}</p>
              <p><strong>Stato:</strong> {getStatusInItalian(assignment.status)}</p>
              
              {assignment.status === 'completed' && assignment.elapsedTime && (
                <p><strong>Tempo Impiegato:</strong> {formatElapsedTime(assignment.elapsedTime)}</p>
              )}
              
              {(assignment.status === 'in progress' || (assignment.status === 'assigned' && new Date(assignment.executionDate.seconds * 1000) <= new Date())) && (
                <p><strong>Tempo Trascorso:</strong> {getCurrentElapsedTime(assignment)}</p>
              )}
              
              <button 
                onClick={() => toggleVisibility(assignment.id, 'notes')}
                className="mt-2 bg-blue-500 text-white px-2 py-1 rounded text-sm"
              >
                {visibilityState[assignment.id]?.notes ? 'Nascondi Note' : 'Mostra Note'}
              </button>
              {visibilityState[assignment.id]?.notes && (
                <p><strong>Note:</strong> {assignment.notes || 'N/A'}</p>
              )}
              
              <button 
                onClick={() => toggleVisibility(assignment.id, 'jobs')}
                className="mt-2 ml-2 bg-green-500 text-white px-2 py-1 rounded text-sm"
              >
                {visibilityState[assignment.id]?.jobs ? 'Nascondi Lavori' : 'Mostra Lavori'}
              </button>
              {visibilityState[assignment.id]?.jobs && assignment.jobs && (
                <div className="mt-2">
                  <h4 className="font-semibold">Lavori:</h4>
                  <ul className="list-disc list-inside">
                    {assignment.jobs.map((job, index) => (
                      <li key={index}>{job.name}</li>
                    ))}
                  </ul>
                </div>
              )}
              
              <button 
                onClick={() => toggleVisibility(assignment.id, 'materials')}
                className="mt-2 ml-2 bg-yellow-500 text-white px-2 py-1 rounded text-sm"
              >
                {visibilityState[assignment.id]?.materials ? 'Nascondi Materiali' : 'Mostra Materiali'}
              </button>
              {visibilityState[assignment.id]?.materials && (
                <div className="mt-2">
                  <h4 className="font-semibold">Materiali:</h4>
                  {assignment.jobs ? (
                    assignment.jobs.map((job, jobIndex) => (
                      <div key={jobIndex}>
                        <h5 className="font-medium">{job.name}:</h5>
                        <ul className="list-disc list-inside ml-4">
                          {job.materials.map((material, materialIndex) => (
                            <li key={materialIndex}>
                              {material.name} - {material.quantity} {material.unit}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))
                  ) : (
                    <ul className="list-disc list-inside">
                      {assignment.materials.map((material, index) => (
                        <li key={index}>{material.name} - {material.quantity} {material.unit}</li>
                      ))}
                    </ul>
                  )}
                </div>
              )}

              <div className="mt-4 space-x-2">
                <button
                  onClick={() => handleStatusChange(assignment.id, 'in progress')}
                  className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                  disabled={assignment.status === 'in progress' || assignment.status === 'completed'}
                >
                  Start
                </button>
                <button
                  onClick={() => handleStatusChange(assignment.id, 'completed')}
                  className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                  disabled={assignment.status === 'completed'}
                >
                  Complete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default EmployeeAssignments;